/**
 * Created by iamso on 18.10.18.
 */

var TransporterTile = function (x, y) {
    BaseTile.call(this, x, y);

    this.direction = 0;
    this.connected = false;

    Game.currentGame.counter.registerStage(200, TransporterTile.process);
};

TransporterTile.prototype = Object.create(BaseTile.prototype);
TransporterTile.prototype.constructor = TransporterTile;

TransporterTile.prototype.getViewClass = function () {
    return TransporterTileView;
};

TransporterTile.prototype.save = function () {
    var saveData = TransporterTile.CODES;
    saveData = saveData.concat([this.direction]);
    return saveData;
};

TransporterTile.prototype.load = function (data) {
    data.shift();
    this.direction = parseInt(data[0]);
    data.shift();
};

TransporterTile.prototype.bundleId = function () {
    return "transporter";
};

TransporterTile.prototype.getDestination = function (direction) {
    if (direction === undefined) {
        direction = this.direction;
    }

    var offset = TransporterTile.directions[direction];
    return {
        x: this.x + offset.x,
        y: this.y + offset.y
    };
};

TransporterTile.processBelt = function (belt) {
    var firstCell = belt[belt.length - 1].getMyCell();

    for (var i = belt.length - 2; i >= 0; i--) {
        belt[i].process(belt, i, belt[i].getMyCell());
    }

    belt[belt.length - 1].process(belt, belt.length - 1, firstCell);
};

TransporterTile.prototype.process = function (belt, i, cellToMove) {
    if (!this.canMove) {
        return;
    }

    this.canMove = false;
    var dest = (i === belt.length - 1) ? belt[0] : belt[i + 1];

    this.onProcessListener();

    if (cellToMove) {
        cellToMove.beforeMoveByTransporter(dest);
        if (Math.abs(cellToMove.x - dest.x) > 1 || Math.abs(cellToMove.y - dest.y) > 1) {
            Game.currentGame.counter.playActions([
                function (callback) {
                    cellToMove.animate(BaseCell.ANIMATION_TRANSPORTER_LOOP_START);

                    callback();
                },

                Game.currentGame.counter.createDelayAction(200),

                function (callback) {
                    cellToMove.move({
                        x: dest.x,
                        y: dest.y
                    }, {
                        moveInterval: 0.5,
                        source: this,
                        scale: 0
                    }, callback);
                }.bind(this),

                function (callback) {
                    cellToMove.animate(BaseCell.ANIMATION_TRANSPORTER_LOOP_FINISH);
                    callback();
                }
            ]);
        } else {
            cellToMove.move({
                x: dest.x,
                y: dest.y
            }, {
                moveInterval: 0.5,
                source: this
            });
        }
    }

    Game.currentGame.field.cells[dest.y][dest.x] = cellToMove;
};

TransporterTile.prepareToMove = function () {
    if (!Game.currentGame) {
        return;
    }

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.floor[i][j] && Game.currentGame.field.floor[i][j].constructor === TransporterTile) {
                Game.currentGame.field.floor[i][j].canMove = true;
                Game.currentGame.field.floor[i][j].connected = false;
            }
        }
    }
};

TransporterTile.process = function () {
    if (!Game.currentGame) {
        return;
    }

    if (!Game.currentGame.transporterBelts) {
        Game.currentGame.transporterBelts = [];

        for (var i = 0; i < Field.SIZE; i++) {
            for (var j = 0; j < Field.SIZE; j++) {
                if (Game.currentGame.field.floor[i][j] && Game.currentGame.field.floor[i][j].constructor === TransporterTile) {
                    var beltTile = Game.currentGame.field.floor[i][j];
                    if (!beltTile.connected) {
                        Game.currentGame.transporterBelts.push(TransporterTile.formBelt(beltTile));
                    }
                }
            }
        }
    }

    Game.currentGame.transporterBelts.forEach(function (belt) {
        TransporterTile.processBelt(belt);
    });
};

TransporterTile.prototype.getPrevDir = function () {
    var prevTile = TransporterTile.getPreviousTile(this);
    var prevDir = prevTile === undefined ? TransporterTile.getOutOfFieldDir(this) : prevTile.direction;
    return prevDir;
};

TransporterTile.getOutOfFieldDir = function (tile) {
    var directions = [TransporterTile.UP, TransporterTile.DOWN, TransporterTile.LEFT, TransporterTile.RIGHT];

    var reverseDirs = {};
    reverseDirs[TransporterTile.UP] = TransporterTile.DOWN;
    reverseDirs[TransporterTile.DOWN] = TransporterTile.UP;
    reverseDirs[TransporterTile.LEFT] = TransporterTile.RIGHT;
    reverseDirs[TransporterTile.RIGHT] = TransporterTile.LEFT;

    var dirs = [];
    directions.forEach(function (direction) {
        var dest = tile.getDestination(direction);
        if (!Game.currentGame.field.isCellInField(dest.y, dest.x)) {
            if (!TransporterTile.checkIfBadDir(reverseDirs[direction], tile)) {
                dirs.push(reverseDirs[direction]);
            }
        }
    });

    if (dirs.indexOf(tile.direction) !== -1 || dirs.length === 0) {
        return tile.direction;
    }
    return dirs[Math.floor(Math.random() * dirs.length)];
};

TransporterTile.checkIfBadDir = function (direction, tile) {
    if (TransporterTile.directions[direction].x + TransporterTile.directions[tile.direction].x === 0) {
        if (TransporterTile.directions[direction].y + TransporterTile.directions[tile.direction].y === 0) {
            return true;
        }
    }
    return false;
};

TransporterTile.getPreviousTile = function (tile) {
    var directions = [TransporterTile.UP, TransporterTile.DOWN, TransporterTile.LEFT, TransporterTile.RIGHT];

    var previousTile = undefined;

    if (Game.currentGame.field.floor) {
        directions.forEach(function (direction) {
            var dest = tile.getDestination(direction);
            var sideTile = Game.currentGame.field.floor[dest.y] ? Game.currentGame.field.floor[dest.y][dest.x] : undefined;
            if (sideTile && (sideTile.constructor === TransporterTile) && !sideTile.connected) {
                if (sideTile.getDestination().x === tile.x && sideTile.getDestination().y === tile.y) {
                    previousTile = sideTile;
                }
            }
        });
    }
    return previousTile;
};

TransporterTile.getNextTile = function (tile) {
    var directions = [TransporterTile.UP, TransporterTile.DOWN, TransporterTile.LEFT, TransporterTile.RIGHT];

    var nextTile = undefined;

    if (Game.currentGame.field.floor) {
        directions.forEach(function (direction) {
            var dest = tile.getDestination(direction);
            var sideTile = Game.currentGame.field.floor[dest.y] ? Game.currentGame.field.floor[dest.y][dest.x] : undefined;
            if (sideTile && (sideTile.constructor === TransporterTile) && !sideTile.connected) {
                if (tile.getDestination().x === sideTile.x && tile.getDestination().y === sideTile.y) {
                    nextTile = sideTile;
                }
            }
        });
    }
    return nextTile;
};

TransporterTile.formBelt = function (beltTile) {
    var firstBeltTile = beltTile;
    var previousBeltTile = TransporterTile.getPreviousTile(beltTile);
    while (previousBeltTile && previousBeltTile !== beltTile) {
        firstBeltTile = previousBeltTile;
        previousBeltTile = TransporterTile.getPreviousTile(previousBeltTile);
    }

    var belt = [];
    var nextBeltTile = firstBeltTile;
    while (nextBeltTile) {
        belt.push(nextBeltTile);
        nextBeltTile.connected = true;
        nextBeltTile = TransporterTile.getNextTile(nextBeltTile);
    }

    return belt;
};

TransporterTile.prototype.onAnimate = function () {};
TransporterTile.prototype.explode = function () {};
TransporterTile.prototype.hurt = function () {};
TransporterTile.prototype.onSetImage = function () {};
TransporterTile.prototype.onProcessListener = function () {};

TransporterTile.CODES = [">"];
TransporterTile.UP = 0;
TransporterTile.RIGHT = 1;
TransporterTile.DOWN = 2;
TransporterTile.LEFT = 3;

TransporterTile.directions = {};
TransporterTile.directions[TransporterTile.UP] = { x: 0, y: -1 };
TransporterTile.directions[TransporterTile.DOWN] = { x: 0, y: 1 };
TransporterTile.directions[TransporterTile.LEFT] = { x: -1, y: 0 };
TransporterTile.directions[TransporterTile.RIGHT] = { x: 1, y: 0 };
