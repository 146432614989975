/**
 * Created by vlad on 18.12.18.
 */

RabbitTileView.prototype.hurtAnimation = function () {
    this.coverAnimation = BaseAnimation.factoryLimited(bundles.rabbit.jsons.rabbit_wrapper_spine1_json);
    this.addChild(this.coverAnimation);
    this.coverAnimation.setPosition(this.width / 2, this.height / 2);
    
    this.coverAnimation.runAnimate(this.tile.lives, function () {
        this.coverAnimation.visible = false;
        if (this.tile.lives > 0) {
            this.cover.setSpriteFrame(bundles.rabbit.frames.rabbit_tile_cover_1);
            this.cover.visible = true;
        }
    }.bind(this), this.cover);

    cleverapps.audio.playSound(bundles.rabbit.urls.bush_collect_effect);
};

RabbitTileView.prototype.createImage = function (tile) {
    var lives = tile.lives;
    if (lives < 1) {
        lives = 1;
    }
    return new cc.Sprite(bundles.rabbit.frames["rabbit_tile_cover_" + lives]);
};

RabbitTileView.prototype.startExploding = function () {
    if (!this.tile.isRoot) {
        return;
    }

    this.stopAllActions();
    var pos = this.image.getPosition();
    var object = this.tile;

    if (Game.currentGame) {
        var fieldView = Game.currentGame.field.onGetView();
        Game.currentGame.counter.setTimeout(function () {}, 700);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                if (Game.currentGame) {
                    var birdAnimation = BaseAnimation.factoryLimited(bundles.rabbit.jsons.rabbit_json, BaseAnimation.DEFAULT_LIMIT, bundles.rabbit.frames["rabbit_tile_" + this.tile.size]);
                    fieldView.addChild(birdAnimation);
                    birdAnimation.tileSize = object.size;
                    birdAnimation.setLocalZOrder(11);
                    birdAnimation.setPositionRound(fieldView.convertToNodeSpace(this.convertToWorldSpace(pos)));
                    birdAnimation.runAnimate(this.tile.size, function () {});

                    cleverapps.audio.playSound(bundles.rabbit.urls.bird_collect_effect);
    
                    var goal = Game.currentGame.goals.findTargetFor(object.getGoalId());
                    goal.collect(1, birdAnimation, {
                        callback: function () {
                            if (birdAnimation.finishAnimation && typeof birdAnimation.finishAnimation === "function") {
                                birdAnimation.finishAnimation();
                            }
                        }
                    });
                }
                if (this.coverAnimation) {
                    this.coverAnimation.finishAnimation(); // new cc.RemoveSelf() can be called before coverAnimation
                    // is finished from hurtAnimation call and we won't put it in pool otherwise
                }
            }.bind(this)),
            new cc.RemoveSelf()
        ));
    }
};