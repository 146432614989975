/**
 * Created by vladislav on 28.01.2022
 */

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
});

cleverapps.overrideStyles(cleverapps.styles.DragonCellView, {
    goal: {
        scale: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.CakeCellView, {
    centerRadius: 60,
    startDistance: 260,
    positions: {
        "1": [
            { x: -42, y: 12 },
            { x: -60, y: 60 },
            { x: -12, y: 45 }
        ],
        "2": [
            { x: 16, y: 51 },
            { x: 57, y: 57 },
            { x: 52, y: 15 }
        ],
        "3": [
            { x: -12, y: -37 },
            { x: -62, y: -57 },
            { x: -77, y: -19 }
        ],
        "4": [
            { x: 89, y: -23 },
            { x: 32, y: -30 },
            { x: 19, y: -76 }
        ]

    }
});

cleverapps.overrideStyles(cleverapps.styles.BlanketCellView, {
    centerImageElement: {
        scale: 1.25,
        dy: 6
    }
});

cleverapps.overrideStyles(cleverapps.styles.LiveLineComponentView, {
    BlanketCellView: {
        dx: 2,
        dy: 10
    }
});

cleverapps.overrideStyles(cleverapps.styles.SmallPetardCellView, {
    livesBg: {
        x: 0,
        y: 60
    },
    livesText: {
        x: 0,
        y: -17
    }
});

cleverapps.overrideStyles(cleverapps.styles.MovesCellView, {
    text: {
        x: 0,
        plus: false,
        scale: 0.85
    }
});

cleverapps.overrideStyles(cleverapps.styles.MultiColorCellView, {
    centerAnimation: {
        scale: 1.0
    },

    shakeField: {
        delay: 300
    }
});

cleverapps.overrideStyles(cleverapps.styles.ColorFulAnimationView, {
    active: false
});

cleverapps.overrideStyles(cleverapps.styles.MultiColorBombCellView, {
    items: {
        "6": {
            scale: 1.3,
            positions: [
                { x: -32, y: 36, zOrder: 0 },
                { x: 32, y: 36, zOrder: 0 },
                { x: 52, y: 0, zOrder: 1 },
                { x: 32, y: -36, zOrder: 2 },
                { x: -32, y: -36, zOrder: 2 },
                { x: -52, y: 0, zOrder: 1 }
            ]
        },
        "5": {
            scale: 1.3,
            positions: [
                { x: 0, y: 34, zOrder: 0 },
                { x: 43, y: 4, zOrder: 1 },
                { x: 32, y: -30, zOrder: 2 },
                { x: -32, y: -30, zOrder: 2 },
                { x: -43, y: 4, zOrder: 1 }
            ]
        },
        "4": {
            scale: 1.3,
            positions: [
                { x: 0, y: 34, zOrder: 0 },
                { x: 40, y: 0, zOrder: 1 },
                { x: 0, y: -28, zOrder: 2 },
                { x: -40, y: 0, zOrder: 1 }
            ]
        },
        "3": {
            scale: 1.3,
            positions: [
                { x: -36, y: 0, zOrder: 1 },
                { x: 36, y: 0, zOrder: 1 },
                { x: 0, y: -34, zOrder: 2 }
            ]
        },
        "2": {
            scale: 1.3,
            positions: [
                { x: -12, y: 12, zOrder: 0 },
                { x: 12, y: -12, zOrder: 1 }
            ]
        },
        "1": {
            scale: 1.3,
            positions: [
                { x: 0, y: 0, zOrder: 0 }
            ]
        }
    },
    goal: {
        scale: 1.0
    }
});

cleverapps.overrideStyles(cleverapps.styles.IceMakerCellView, {
    lives: {
        x: 85,
        y: 85,
        scale: [0.8, 0.8]
    },

    animations: {
        horizontal: false,
        goal: true
    }
});

cleverapps.overrideStyles(cleverapps.styles.FishCellView, {
    onclickAnimation: "animation"
});

cleverapps.overrideStyles(cleverapps.styles.BombCellView, {
    timer: {
        x: 0,
        y: 0
    },

    danger: {
        scale: 1.05
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrabDecoratorCellView, {
    goalImage: {
        dy: 25
    }
});

cleverapps.overrideStyles(cleverapps.styles.IceTileView, {
    potMargin: {
        x: 10,
        y: 0
    },

    collectAnimation: true
});

cleverapps.overrideStyles(cleverapps.styles.MarkView, {
    positions: {
        "ColorComponent": {
            "d": { x: { align: "center", dx: -10 }, y: { align: "center", dy: 0 } },
            "e": { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } },
            "w": { x: { align: "center" }, y: { align: "center", dy: -8 } },
            "c": { x: { align: "center", dx: -20 }, y: { align: "center", dy: 5 } },
            "b": { x: { align: "center", dx: 3 }, y: { align: "center", dy: -10 } },
            "a": { x: { align: "center", dx: -20 }, y: { align: "center", dy: 0 } }
        },

        "GoalCoefComponent": {
            "d": { x: { align: "center", dx: -8 }, y: { align: "center", dy: 5 } },
            "e": { x: { align: "center", dx: 17 }, y: { align: "center", dy: -12 } },
            "w": { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } },
            "c": { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } },
            "b": { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } },
            "a": { x: { align: "center", dx: 0 }, y: { align: "center", dy: 0 } }
        }
    },

    animation: {
        start: {
            duration: 0.6
        },
        skins: true
    },

    flyAnimation: {
        scale: 1.0
    },

    smile: {
        animationName: ["smile_1", "smile_2"]
    }
});

cleverapps.overrideStyles(cleverapps.styles.GingerHouseCellView, {
    door: {
        x: -90,
        y: 5,
        scaleX: 0.5,
        scaleY: 0.5,
        zOrder: 2
    },

    window: {
        x: 90,
        y: 50,
        scaleX: 0.5,
        scaleY: 0.5,
        zOrder: 2
    },

    roof: {
        x: -17,
        y: 66,
        scaleX: 0.85,
        scaleY: 0.85,
        zOrder: 4
    },

    wall: {
        x: 0,
        y: -40,
        scaleX: 1.9,
        scaleY: 1.5,
        zOrder: 3
    }
});

cleverapps.overrideStyles(cleverapps.styles.HeroCellView, {
    animation: {
        overshoot: false,
        direction: {
            left: "out_left",
            right: "out_right",
            up: "out_up",
            down: "out_down"
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.Rocket, {
    startDX: -20,
    flyUp: 170
});

cleverapps.overrideStyles(cleverapps.styles.FireworkCellView, {
    bomb: {
        startDX: 16,
        startDY: 40
    }
});

cleverapps.overrideStyles(cleverapps.styles.DirtMakerCellView, {
    idleAnimation: "_idle",

    shot: {
        delay: 0.5
    }
});

cleverapps.overrideStyles(cleverapps.styles.LollipopCellView, {
    smile: true
});

cleverapps.overrideStyles(cleverapps.styles.TransporterTileView, {
    animateOnProcess: true
});

cleverapps.overrideStyles(cleverapps.styles.FieldBoosterView, {
    amount: {
        x: -58,
        y: 42
    },
    price: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    },
    background: [
        {
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            width: 160,
            height: 160
        },
        {
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            width: 150,
            height: 180
        },
        {
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            width: 150,
            height: 180
        }
    ]
});