/**
 * Created by andrey on 14.09.17.
 */

var StarChestWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.reward = cleverapps.starChest.getReward();

        this.content = this.createContent();

        var buttons = this.createButtons();

        this._super({
            title: "StarChestWindow.title",
            name: "starchestwindow",
            content: this.content,
            buttons: buttons,
            help: function () {
                new GuideWindow({
                    name: "StarChestGuideWindow",
                    bundle: bundles.star_chest_guide_window
                });
            }
        });

        if (this.shareCheckBox) {
            this.shareCheckBox.setCascadeOpacityEnabledRecursively(true);
            this.shareCheckBox.setOpacity(0);
        }

        if (!cleverapps.starChest.locked) {
            this.open();
        }
    },

    open: function () {
        this.openningChest = true;

        this.chest.setAnimation(0, "idle_ready", true);
        this.chest.addChild(this.createRewardsList());
        cleverapps.focusManager.display({
            focus: "StarChestWindowReward",
            stack: true,
            actions: [
                function (f) {
                    this.getEventNodes = function () {
                        return [];
                    };
                    cleverapps.timeouts.setTimeout(function () {
                        if (this.visible) {
                            cleverapps.audio.playSound(bundles.main.urls.star_chest_reward_effect);
                        }
                    }.bind(this), 100);
                    f();
                }.bind(this),
                function (f) {
                    this.runAction(
                        this.createOpenActions(f)
                    );
                }.bind(this),
                function (f) {
                    cleverapps.timeouts.setTimeout(function () {
                        this.getEventNodes = function () {
                            return this._super();
                        };
                        f();
                        this.close();
                    }.bind(this), 400);
                }.bind(this)
            ]
        });
    },

    createOkButton: function () {
        var styles = cleverapps.styles.StarChestWindow;

        return new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "OK",
            onClicked: this.close.bind(this)
        });
    },

    createAskButton: function () {
        var styles = cleverapps.styles.StarChestWindow;

        return new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "StarChestWindow.askFriends",
            onClicked: function () {
                cleverapps.SocialManager.enforceLogin(function (code) {
                    if (code !== cleverapps.CODE_SUCCEED) {
                        return;
                    }

                    FriendsCache.loadFriends(function () {
                        new SelectFriendWindow("askStars", function (ids, onSuccess) {
                            cleverapps.requests.send(ids, "askStarRequest", function () {
                                ids.forEach(function (id) {
                                    if (cleverapps.friends.inApp(id)) {
                                        levels.friendRequests.sendServerRequest(id, levels.FriendRequest.TYPE_ASK_STAR);
                                    }
                                });

                                onSuccess();
                            });
                        });
                    });
                });
            }
        });
    },

    createButtons: function () {
        if (!cleverapps.starChest.locked) {
            return [];
        }

        return cleverapps.flags.requestsEnabled ? [this.createAskButton()] : [this.createOkButton()];
    },

    createContent: function () {
        var styles = cleverapps.styles.StarChestWindow;

        this.chestNode = this.createChest();

        this.bar = this.createBar();

        return new cleverapps.Layout([this.chestNode, this.bar], {
            padding: styles.padding,
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createChest: function () {
        var styles = cleverapps.styles.StarChestWindow;

        this.chest = new cleverapps.Spine(bundles.star_chest.jsons.chest_json);
        this.chest.setAnimation(0, "idle", true);

        var chestNode = new cc.Node();
        chestNode.setAnchorPoint(0.5, 0.5);
        chestNode.setContentSize2(styles.chest);
        chestNode.addChild(this.chest);
        this.chest.setPositionRound(styles.chest);

        return chestNode;
    },

    createBar: function () {
        var styles = cleverapps.styles.StarChestWindow;

        var bar = new ScaledProgressBar({
            type: styles.bar.type,
            barText: {
                text: "%from%/%to%[[",
                font: cleverapps.styles.FONTS.STAR_CHEST_PROGRESS_TEXT,
                icons: {
                    "[[": bundles.star_chest.frames.star_png
                }
            }
        });
        bar.setLength(styles.bar.width);
        bar.setGoal(cleverapps.StarChest.GOAL_AMOUNT);
        bar.setPercentage(cleverapps.starChest.stars);

        return bar;
    },

    createRewardsList: function () {
        var styles = cleverapps.styles.StarChestWindow.rewards;
        var rewardsList = this.rewardsList = new RewardsListComponent(this.reward, {
            columns: 2,
            event: cleverapps.EVENTS.EARN.STAR_CHEST,
            font: cleverapps.styles.FONTS.STAR_CHEST_REWARD_TEXT,
            fitToBox: styles
        });

        rewardsList.setPositionRound(styles);
        rewardsList.targets.forEach(function (reward) {
            reward.setVisible(false);
        });
        rewardsList.receiveRewards();

        return rewardsList;
    },

    createReceiveRewardsActions: function (f) {
        return new cc.Sequence(
            new cc.CallFunc(function () {
                this.rewardsList.targets.forEach(function (reward) {
                    reward.runAction(AnimationsLibrary.showUp(reward));
                });
            }.bind(this)),
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                this.rewardsList.receiveRewardsAnimation({
                    callback: f
                });
            }.bind(this))
        );
    },

    createOpenActions: function (f) {
        return new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(function () {
                this.chest.setAnimationAndIdleAfter("open", "idle_open");
            }.bind(this)),
            new cc.DelayTime(0.5),
            this.createReceiveRewardsActions(f),
            new cc.CallFunc(function () {
                this.openningChest = false;

                cleverapps.starChest.open();
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STAR_CHEST_OPEN);
            }.bind(this))
        );
    },

    close: function () {
        if (!this.openningChest) {
            this._super();
        }
    }
});

StarChestWindow.prototype.listBundles = function () {
    return ["star_chest"];
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    STAR_CHEST_PROGRESS_TEXT: {
        name: "default",
        size: 44,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    STAR_CHEST_REWARD_TEXT: {
        name: "default",
        size: 36,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.StarChestWindow = {
    margin: 50,
    padding: {
        top: 130,
        left: 20,
        right: 20,
        bottom: 25
    },

    button: {
        width: 250,
        height: 100
    },

    chest: {
        width: 300,
        height: 200,

        x: { align: "center" },
        y: { align: "center" }
    },

    bar: {
        type: {
            progress: bundles.progress_bar.frames.bar_chest,
            background: bundles.progress_bar.frames.bg_chest
        },
        width: 500
    },

    rewards: {
        x: { align: "center" },
        y: { align: "center", dy: 50 },
        width: 330,
        height: 330
    }
};