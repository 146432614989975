/**
 * Created by Oleg on 12.09.2019.
 */

BlanketCellView.prototype.showUp = function () {
    var scaleX = this.getScaleX();
    if (!scaleX) {
        scaleX = 1;
    } else {
        this.setScaleX(0);
    }
    this.runAction(
        new cc.ScaleTo(0.6, scaleX, 1).easing(cc.easeBackOut())
    );
};
