/**
 * Created by mac on 2019-10-14
 */

var BuildingLockedWindow = BuildingInfoWindow.extend({
    onWindowLoaded: function (building) {
        this._super(building, "buildinglockedwindow");
    },

    getButton: function () {
        return {
            text: Messages.get("Go").toUpperCase(),
            width: cleverapps.styles.BuildingInfoWindow.button.width,
            onPressed: this.onPressed.bind(this)
        };
    },

    onPressed: function () {
        this.close();

        cleverapps.focusManager.distract({
            focus: "moveToCurrent",
            actions: [
                function (f) {
                    cleverapps.farm.scrollTo(cleverapps.farm.getBuilding(), f);
                },

                function (f) {
                    setTimeout(f, 500);
                },

                function (f) {
                    new QuestWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            ]
        });
    },

    getInfo: function () {
        var activeBuilding = cleverapps.farm.getBuilding();

        return cleverapps.UI.generateTTFText("BuildingLockedWindow.text", cleverapps.styles.FONTS.WINDOW_TEXT, {
            currentBuilding: Messages.get("building." + activeBuilding.name)
        });
    }
});