/**
 * Created by decipaliz on 22.05.2024
 */

var PrizeCalendarTileView = cc.Node.extend({
    ctor: function (index, prizeCalendar, rewards) {
        this._super();

        this.type = prizeCalendar.getTileType(index);
        this.index = index;
        this.prizeCalendar = prizeCalendar;
        this.rewardsList = rewards;

        this.setContentSize2(cleverapps.styles.PrizeCalendarTileView);
        this.setAnchorPoint(0.5, 0.5);
        this.createContent();

        this.prizeCalendar.on("receiveTile", this.onReceive.bind(this));
        this.prizeCalendar.on("changeType", this.changeType.bind(this));
    },

    isLastTile: function () {
        return this.index === this.prizeCalendar.getTilesAmount() - 1;
    },

    lockedTileClick: function () {
        if (this.isLastTile() || !(cleverapps.styles.PrizeCalendarTileView.rewards && cleverapps.styles.PrizeCalendarTileView.rewards.showTextHint)) {
            var rewards = new RewardsListComponent(this.rewardsList, {
                font: cleverapps.styles.FONTS.PRIZE_CALENDAR_REWARD
            });
            cleverapps.tooltipManager.onClick(this, {
                content: rewards,
                size: cleverapps.styles.PrizeCalendarTileView.rewardsTooltip,
                location: cleverapps.UI.Tooltip.LOCATION_ABOVE
            });
        } else if (!this.isLastTile()) {
            cleverapps.centerHint.createTextHint("PrizeCalendarTile.Locked");
        }
    },

    onTileClick: function () {
        if (this.type === PrizeCalendarTileView.TYPE_ADS_AVAILABLE) {
            this.prizeCalendar.onShowAds();
        } else if (this.type === PrizeCalendarTileView.TYPE_HARD) {
            this.prizeCalendar.onSpendHard();
        } else if (this.type === PrizeCalendarTileView.TYPE_FREE) {
            this.prizeCalendar.onClaimTile();
        } else if (this.type === PrizeCalendarTileView.TYPE_LOCKED) {
            this.lockedTileClick();
        }
    },

    onReceive: function (tileIndex) {
        if (this.index !== tileIndex) {
            return;
        }
        if (this.type === PrizeCalendarTileView.TYPE_RECEIVED) {
            return;
        }

        var styles = cleverapps.styles.PrizeCalendarTileView;

        var bg, style;
        if (styles.innerBg && styles.innerBg.receiveAnimation) {
            bg = this.innerBg;
            style = styles.innerBg.receiveAnimation;
        } else if (styles.outerBg && styles.outerBg.receiveAnimation) {
            bg = this.outerBg;
            style = styles.outerBg.receiveAnimation;
        }

        if (bg) {
            if (this.titleBg) {
                this.titleBg.runAction(new cc.FadeOut(0.3).easing(cc.easeOut(2)));
            }

            var actions = [
                function (f) {
                    if (styles.ticketAnimation && !this.isLastTile()) {
                        this.prizeCalendar.trigger("ticketAnimation", f, this.rewardsList);
                        return;
                    }
                    f();
                }.bind(this),

                function (f) {
                    bg.setAnimation(0, this.isLastTile() ? style.last : style.default, false);
                    bg.setCompleteListenerOnce(f);
                }.bind(this),

                function (f) {
                    if (!styles.ticketAnimation || this.isLastTile()) {
                        this.collect(f);
                        return;
                    }
                    f();
                }.bind(this),

                function (f) {
                    this.runAction(new cc.CallFunc(function () {
                        this.type = PrizeCalendarTileView.TYPE_RECEIVED;
                        this.createContent();
                        f();
                    }.bind(this)));
                }.bind(this),

                function (f) {
                    if (this.mark) {
                        this.mark.setAnimationAndIdleAfter("popup", "idle");
                    }
                    f();
                }.bind(this)
            ];

            new ActionPlayer(actions).play();
            return;
        }

        this.collect();
        this.type = PrizeCalendarTileView.TYPE_RECEIVED;
        this.createContent();
        this.mark.setAnimationAndIdleAfter("popup", "idle");
    },

    collect: function (callback) {
        var styles = cleverapps.styles.PrizeCalendarTileView;

        if (this.isLastTile()) {
            this.prizeCalendar.trigger("chestAnimation", this.rewardsList);
        } else {
            var flyingAnimation = styles.rewards && styles.rewards.flyingAnimation;

            this.rewardsList.collectRewardsAnimation(this.rewards || this, {
                flyingAnimation: flyingAnimation || Reward.JUMP_COLLECT_ANIMATION,
                allAtOnce: true,
                small: false,
                beginScale: this.rewards && this.rewards.content ? this.rewards.content.scale : this.scale,
                callback: callback
            });
        }
    },

    createContent: function () {
        this.removeAllChildren();
        this.content = new cc.Node();
        this.content.setContentSize2(this.getContentSize());
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setPositionRound({ align: "center" }, { align: "center" });
        this.addChild(this.content);

        cleverapps.UI.onClick(this.content, this.onTileClick.bind(this), {
            onDoubleClick: cleverapps.config.debugMode ? function () {
                if (this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE) {
                    this.prizeCalendar.resetAdTimer();
                    this.title.countDown.resetTimeLeft(0);
                }
            }.bind(this) : undefined
        });

        this.createBackgrounds();
        this.createTitle();
        this.createMarks();
        this.createArrow();
        this.createRewards();
    },

    createBackgrounds: function () {
        var styles = cleverapps.styles.PrizeCalendarTileView;

        var createBg = function (style) {
            if (!style) {
                return;
            }

            var bg;
            if (style.scale9) {
                var frame;
                if (this.isLastTile()) {
                    frame = style.scale9.chest;
                } else if (this.type === PrizeCalendarTileView.TYPE_LOCKED
                    || (this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE)) {
                    frame = style.scale9.locked;
                } else {
                    frame = style.scale9.unlocked;
                }
                bg = cleverapps.UI.createScale9Sprite(frame, cleverapps.UI.Scale9Rect.TwoPixelXY);
                bg.setContentSize2(style);
            } else if (style.spine) {
                bg = new cleverapps.Spine(style.spine.json);
                bg.setAnimation(0, this.getTileAnimation(style.spine.animations), true);
                bg.setVisible(true);
            } else if (style.sprite) {
                bg = new cc.Sprite(style.sprite);
                if (style.zOrder) {
                    bg.setLocalZOrder(style.zOrder);
                }
            }

            bg.setPositionRound(style);
            this.content.addChild(bg);
            return bg;
        }.bind(this);

        this.outerBg = createBg(styles.outerBg);
        this.innerBg = createBg(styles.innerBg);
    },

    createTitle: function () {
        var styles = cleverapps.styles.PrizeCalendarTileView;

        if (!styles.title) {
            return;
        }

        if (this.type === PrizeCalendarTileView.TYPE_LOCKED || this.type === PrizeCalendarTileView.TYPE_RECEIVED) {
            return;
        }

        if (styles.titleBg.invisible) {
            this.titleBg = new cc.Node();
        } else if (bundles.prizecalendar.frames.title_bg) {
            this.titleBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.title_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            cleverapps.rewardedAdsManager.onRewardedRefresh(this.changeTitleState.bind(this), this);
            this.changeTitleState();
        } else if (this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE) {
            this.titleBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.title_bg_locked, cleverapps.UI.Scale9Rect.TwoPixelXY);
        } else {
            this.titleBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.title_bg_unlocked, cleverapps.UI.Scale9Rect.TwoPixelXY);
            cleverapps.rewardedAdsManager.onRewardedRefresh(this.changeTitleState.bind(this), this);
            this.changeTitleState();
        }
        this.titleBg.setContentSize2(styles.titleBg);
        this.titleBg.setPositionRound(styles.titleBg);
        this.content.addChild(this.titleBg);

        if (this.type === PrizeCalendarTileView.TYPE_FREE
            || (this.type === PrizeCalendarTileView.TYPE_ADS_AVAILABLE && this.prizeCalendar.isWaitInsteadOfAds())) {
            this.title = cleverapps.UI.generateTTFText("PrizeCalendarTile.Claim", cleverapps.styles.FONTS.PRIZE_CALENDAR_TITLE);
            this.title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            this.title.setDimensions(styles.title.width, 0);
            this.title.fitTo(undefined, styles.title.height);
        } else if (this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE) {
            this.title = new cleverapps.CountDownView(
                new cleverapps.CountDown(this.prizeCalendar.timeUntilNextAd(), {
                    onFinish: function () {
                        if (this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE) {
                            this.changeType();
                        }
                    }.bind(this)
                }),
                {
                    font: cleverapps.styles.FONTS.PRIZE_CALENDAR_TITLE,
                    showHours: false
                }
            );
            this.title.fitTo(styles.title.width, styles.title.height);
        } else if (this.type === PrizeCalendarTileView.TYPE_ADS_AVAILABLE) {
            this.title = new cc.Sprite(bundles.prizecalendar.frames.title_ads);
        } else if (this.type === PrizeCalendarTileView.TYPE_HARD) {
            this.title = new TextWithIcon(this.prizeCalendar.getTilePrice(this.index) + "$$", {
                font: cleverapps.styles.FONTS.PRIZE_CALENDAR_TITLE,
                width: styles.title.width,
                height: styles.title.height
            });
        }

        this.title.setCascadeOpacityEnabled(true);
        this.titleBg.setCascadeOpacityEnabled(true);
        this.title.setPositionRound(styles.title);
        this.titleBg.addChild(this.title);
    },

    createMarks: function () {
        var styles = cleverapps.styles.PrizeCalendarTileView;

        if (this.type !== PrizeCalendarTileView.TYPE_RECEIVED && this.type !== PrizeCalendarTileView.TYPE_LOCKED) {
            return;
        }
        if (!styles.mark) {
            return;
        }
        if (styles.mark.checkOnly && this.type === PrizeCalendarTileView.TYPE_LOCKED) {
            return;
        }

        this.mark = new cleverapps.Spine(
            this.type === PrizeCalendarTileView.TYPE_LOCKED ? bundles.prizecalendar.jsons.padlock_json : bundles.prizecalendar.jsons.check_json
        );
        this.mark.setAnimation(0, "idle", true);
        this.mark.setPositionRound(styles.mark);
        this.content.addChild(this.mark);
    },

    createArrow: function () {
        var styles = cleverapps.styles.PrizeCalendarTileView;

        if (styles.arrow) {
            var orientation = this.getArrowOrientation();
            if (orientation === PrizeCalendarTileView.ARROW_NONE) {
                return;
            }

            if (bundles.prizecalendar.frames.arrow_png) {
                this.arrow = new cc.Sprite(bundles.prizecalendar.frames.arrow_png);
            } else if (this.type === PrizeCalendarTileView.TYPE_LOCKED
                || (this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE)) {
                this.arrow = new cc.Sprite(bundles.prizecalendar.frames.arrow_locked);
            } else {
                this.arrow = new cc.Sprite(bundles.prizecalendar.frames.arrow_unlocked);
            }

            switch (orientation) {
                case PrizeCalendarTileView.ARROW_DOWN:
                    this.arrow.setRotation(90);
                    this.arrow.setScaleY(-this.arrow.scaleY);
                    this.arrow.setPositionRound(styles.arrow.down);
                    break;
                case PrizeCalendarTileView.ARROW_LEFT:
                    this.arrow.setScaleX(-this.arrow.scaleX);
                    this.arrow.setPositionRound(styles.arrow.left);
                    break;
                default:
                    this.arrow.setPositionRound(styles.arrow.right);
                    break;
            }

            if (styles.arrow.scaleOnClick) {
                this.content.addChild(this.arrow);
                return;
            }
            this.addChild(this.arrow);
        }
    },

    getArrowOrientation: function () {
        if (this.isLastTile()) {
            return PrizeCalendarTileView.ARROW_NONE;
        }

        switch (this.index) {
            case 2: case 5:
                return PrizeCalendarTileView.ARROW_DOWN;
            case 3: case 4:
                return PrizeCalendarTileView.ARROW_LEFT;
            default:
                return PrizeCalendarTileView.ARROW_RIGHT;
        }
    },

    getTileAnimation: function (style) {
        if (this.isLastTile()) {
            if ([PrizeCalendarTileView.TYPE_LOCKED, PrizeCalendarTileView.TYPE_RECEIVED].indexOf(this.type) === -1
                && style.lastAvailable) {
                return style.lastAvailable;
            }
            return style.last;
        }
        if (this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE && style.adsUnavailable) {
            return style.adsUnavailable;
        }
        if ([PrizeCalendarTileView.TYPE_LOCKED, PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE].indexOf(this.type) !== -1) {
            return style.locked;
        }
        if (this.type === PrizeCalendarTileView.TYPE_RECEIVED) {
            return style.received;
        }
        return style.available;
    },

    getTileChangeAnimationAndIdle: function (newType, style) {
        var from = [PrizeCalendarTileView.TYPE_LOCKED];
        var to = [PrizeCalendarTileView.TYPE_ADS_AVAILABLE, PrizeCalendarTileView.TYPE_HARD, PrizeCalendarTileView.TYPE_FREE];

        if (style.dontWaitForAds) {
            to.push(PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE);
        } else {
            from.push(PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE);
        }

        if ((!this.isLastTile() || style.lastToAvailable)
            && from.indexOf(this.type) !== -1
            && to.indexOf(newType) !== -1) {
            return this.isLastTile() && style.lastToAvailable ? style.lastToAvailable : style.lockedToAvailable;
        }

        if ([PrizeCalendarTileView.TYPE_ADS_AVAILABLE, PrizeCalendarTileView.TYPE_HARD, PrizeCalendarTileView.TYPE_FREE].indexOf(newType) !== -1
            && newType === PrizeCalendarTileView.TYPE_RECEIVED) {
            return this.isLastTile() && style.lastToReceived ? style.lastToReceived : style.availableToReceived;
        }
    },

    createRewards: function () {
        var styles = cleverapps.styles.PrizeCalendarTileView;
        if (!styles.rewards) {
            return;
        }

        if (this.isLastTile()) {
            this.rewards = new cc.Sprite(bundles.prizecalendar.frames.chest_png);
            this.rewards.setPositionRound(styles.rewards.chest);
            if (styles.rays) {
                this.rays = AnimationsLibrary.rays(this.rewards, { skin: "white" });
            }
        } else {
            this.rewards = new RewardsListComponent(this.rewardsList, {
                fitToBox: styles.rewards.fitToBox,
                textMargin: -10,
                font: cleverapps.styles.FONTS.PRIZE_CALENDAR_REWARD
            });
            this.rewards.setPositionRound(styles.rewards);
        }

        if (styles.innerBg && styles.innerBg.rewardsParent) {
            this.innerBg.addChild(this.rewards);
        } else if (styles.outerBg && styles.outerBg.rewardsParent) {
            this.outerBg.addChild(this.rewards);
        }
    },

    changeType: function (tileIndex) {
        if (typeof tileIndex !== "undefined" && tileIndex !== this.index) {
            return;
        }
        var newType = this.prizeCalendar.getTileType(this.index);
        if (newType === this.type) {
            return;
        }

        var styles = cleverapps.styles.PrizeCalendarTileView;

        if (styles.innerBg && styles.innerBg.usesSpin && !this.isLastTile()
            && ((this.type === PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE && newType === PrizeCalendarTileView.TYPE_ADS_AVAILABLE)
                || (this.type === PrizeCalendarTileView.TYPE_LOCKED && newType !== PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE))) {
            this.spinAnimation();
            return;
        }

        var createContent = function () {
            this.type = newType;
            this.createContent();
            if (this.titleBg) {
                this.titleBg.setOpacity(0);
                this.titleBg.runAction(new cc.FadeIn(0.3).easing(cc.easeIn(2)));
            }
        }.bind(this);

        var innerBgChangeAnimation = styles.innerBg && styles.innerBg.changeAnimation && this.getTileChangeAnimationAndIdle(newType, styles.innerBg.changeAnimation);
        var outerBgChangeAnimation = styles.outerBg && styles.outerBg.changeAnimation && this.getTileChangeAnimationAndIdle(newType, styles.outerBg.changeAnimation);

        var wait = 0;
        if (innerBgChangeAnimation) {
            wait++;
        }
        if (outerBgChangeAnimation) {
            wait++;
        }
        if (this.mark && !styles.mark.checkOnly) {
            wait++;
        }

        if (wait === 0) {
            createContent();
            return;
        }

        var waiter = cleverapps.wait(wait, createContent);
        var runChangeAnimation = function (bg, animationAndIdle) {
            bg.setAnimation(0, animationAndIdle.animation, false);
            bg.setCompleteListenerOnce(function () {
                bg.runAction(new cc.CallFunc(function () {
                    bg.setAnimation(0, animationAndIdle.idle, true);
                    waiter();
                }));
            });
        };

        this.runAction(new cc.Sequence(
            new cc.DelayTime(styles.collectDelay),
            new cc.CallFunc(function () {
                this.showUpReward && this.showUpReward();
            }.bind(this)),
            new cc.DelayTime(styles.showUpAnimationDelay),
            new cc.CallFunc(function () {
                if (innerBgChangeAnimation) {
                    runChangeAnimation(this.innerBg, innerBgChangeAnimation);
                }
                if (outerBgChangeAnimation) {
                    runChangeAnimation(this.outerBg, outerBgChangeAnimation);
                }
                if (this.mark && !styles.mark.checkOnly) {
                    this.mark.setAnimation(0, "hide", false);
                    this.mark.setCompleteListenerOnce(function () {
                        this.mark.removeFromParent();
                        delete this.mark;
                        waiter();
                    }.bind(this));
                }
            }.bind(this))
        ));
    },

    spinAnimation: function () {
        if (this.mark) {
            this.mark.setAnimation(0, "hide", false);
            this.mark.setCompleteListenerRemove();
        }

        this.innerBg.runAction(new cc.ScaleTo(0.3, 0, this.innerBg.scaleY).easing(cc.easeIn(2)));
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                this.type = this.prizeCalendar.getTileType(this.index);
                this.createContent();
                var baseScale = this.innerBg.scaleX;
                this.innerBg.setScaleX(0);
                this.innerBg.runAction(new cc.ScaleTo(0.3, baseScale, this.innerBg.scaleY).easing(cc.easeOut(2)));

                if (this.titleBg) {
                    this.titleBg.setOpacity(0);
                    this.titleBg.runAction(new cc.FadeIn(0.3).easing(cc.easeIn(2)));
                }
            }.bind(this))
        ));
    },

    changeTitleState: function () {
        if (this.type !== PrizeCalendarTileView.TYPE_ADS_AVAILABLE || this.prizeCalendar.isWaitInsteadOfAds()) {
            return;
        }
        if (this.titleBg) {
            cleverapps.UI.convertToGrayScale(this.titleBg, cleverapps.rewardedAdsManager.isRewardedAvailable());
        }
    }
});

PrizeCalendarTileView.TYPE_LOCKED = 0;
PrizeCalendarTileView.TYPE_FREE = 1;
PrizeCalendarTileView.TYPE_ADS_AVAILABLE = 2;
PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE = 3;
PrizeCalendarTileView.TYPE_HARD = 4;
PrizeCalendarTileView.TYPE_RECEIVED = 5;

PrizeCalendarTileView.ARROW_RIGHT = 0;
PrizeCalendarTileView.ARROW_DOWN = 1;
PrizeCalendarTileView.ARROW_LEFT = 2;
PrizeCalendarTileView.ARROW_NONE = 3;

cleverapps.styles.PrizeCalendarTileView = {
    width: 170,
    height: 185,
    titleBg: {
        width: 145,
        height: 50,
        x: { align: "center" },
        y: { align: "top", dy: 15 }
    },
    title: {
        x: { align: "center" },
        y: { align: "center" },
        width: 140,
        height: 45
    },

    rewardsTooltip: {
        width: 360,
        height: 180
    }
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PRIZE_CALENDAR_TITLE: {
        name: "nostroke",
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.REWARDS_TEXT_STROKE
    },

    PRIZE_CALENDAR_REWARD: {
        name: "nostroke",
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});