/**
 * Created by mac on 8/03/17.
 */

var LevelTutorialView = cc.Node.extend({
    ctor: function (data) {
        this.data = data;
        Game.levelTutorialView = this;

        this._super();
        var sceneSize = cleverapps.resolution.getSceneSize();
        this.setContentSize2(sceneSize);
        this.setLocalZOrder(12);
        this.setCascadeOpacityEnabled(true);

        this.shadow = new ShadowLayer();
        this.shadow.setLocalZOrder(11);
        this.shadow.createShadow();

        this.fieldView = Game.currentGame.field.onGetView();

        this.prepareData();
        this.moveUpNodes();

        this.createDialogue();

        Game.currentGame.on("tutorial:finishStep", this.finishStep.bind(this), this);

        var moviesView = this.data.moves && Game.currentGame.onGetMovesView();
        var boosterView = this.data.booster !== undefined && Game.currentGame.boosters.onGetBoosterView(this.data.booster);

        if (boosterView) {
            cleverapps.focusManager.setEventNodes([boosterView, this.fieldView]);
        }

        this.createFinger(moviesView || boosterView);

        this.applyControlCells(this.data.light, this.data.booster);

        if (this.data.closeByTouch) {
            setTimeout(function () {
                this.finishByTouch = true;
            }.bind(this), 500);
        }

        Game.currentGame.tutorial.onAfterResize = this.createListener(this.afterResize.bind(this));
    },

    finishStep: function () {
        this.removeFinger();

        this.backNodes();

        if (this.dialogue) {
            this.dialogue.remove();
        }

        this.shadow.removeFromParent();

        this.runAction(new cc.Sequence(
            new cc.FadeTo(0.15, 0),
            new cc.RemoveSelf()
        ));
    },

    createDialogue: function () {
        this.dialogue && this.dialogue.removeFromParent(true);
        this.dialogue = new MinimalDialogue({
            text: this.data.textBlock.text,
            rects: this.data.rects,
            delay: 0.5
        });

        cleverapps.scenes.getRunningScene().addChild(this.dialogue);
        this.dialogue.display();
    },

    prepareData: function () {
        this.data.rects = [];

        if (this.data.control && this.data.control.length > 0) {
            Array.prototype.push.apply(this.data.rects, this.getControlRects());
        }
        if (this.data.light && this.data.light.length > 0) {
            this.data.rects = this.data.rects.concat(this.getLightRects());
        }
        if (this.data.booster !== undefined) {
            this.data.rects.push(cleverapps.boosters.getBoosterById(this.data.booster).onGetView().getSceneBoundingBox());
        }
        if (this.data.moves) {
            this.data.rects.push(cleverapps.scenes.getRunningScene().movesView.getSceneBoundingBox());
        }
        if (this.data.cellBooster) {
            this.data.rects.push(this.getCellBoundingBox([this.data.cellBooster.x, this.data.cellBooster.y]));
        }
    },

    getControlRects: function () {
        var control;
        if (this.data.fingerType && this.data.fingerType === "click") {
            control = [this.data.control[0]];
        } else {
            control = [this.data.control[0], this.data.control[1]];
        }
        return control.map(this.getCellBoundingBox.bind(this));
    },

    getLightRects: function () {
        return this.data.light.map(this.getCellBoundingBox.bind(this));
    },

    getCellBoundingBox: function (tilePos) {
        return this.fieldView.floorView.getChildByTag(tilePos[0] * Field.SIZE + tilePos[1]).getSceneBoundingBox();
    },

    createFinger: function (target) {
        if (this.data.cellBooster) {
            return;
        }

        var fingerType = this.data.fingerType;
        if (!fingerType) {
            if (this.data.control && this.data.control.length > 0) {
                fingerType = "swipe";
            } else {
                fingerType = "click";
            }
        }

        if (fingerType === "click") {
            this.finger = FingerView.hintClick(target || [this.data.control[0]]);
        }

        if (fingerType === "swipe") {
            this.finger = FingerView.hintDrag(this.data.control.slice(0, 2));
        }
    },

    removeFinger: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    },

    applyControlCells: function (allowCells, allowBooster) {
        allowCells = allowCells || [];

        cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: true,
            onTouchBegan: function (touch) {
                if (!this.data.cellBooster) {
                    var pos = this.fieldView.getCellPosition(this.fieldView.convertTouchToNodeSpace(touch));
                    var i;
                    for (i = 0; i < allowCells.length; i++) {
                        if (allowCells[i][0] === pos.col && allowCells[i][1] === pos.row) {
                            return false;
                        }
                    }

                    var boosters = cleverapps.boosters.listBoosters();
                    for (i = 0; i < boosters.length; i++) {
                        var booster = boosters[i];
                        var view = booster.onGetView();
                        if (view.containsTouch(touch) && booster.id === allowBooster) {
                            return false;
                        }
                    }
                }

                if (this.finishByTouch) {
                    this.finishByTouch = false;

                    Game.currentGame.tutorial.finishStep();
                    Game.currentGame.counter.setTimeout(function () {
                    }, 300);
                }
                return true;
            }.bind(this)
        }, this);
    },

    moveUpNodes: function () {
        this.getMoveUpNodes().forEach(function (node) {
            node._setGlobalZOrder(true);
        });
    },

    backNodes: function () {
        this.getMoveUpNodes().forEach(function (node) {
            node._setGlobalZOrder(false);
        });
    },

    getMoveUpNodes: function () {
        var stepData = this.data;
        var game = Game.currentGame;

        var moveUpNodes = [this.fieldView.selection];

        if (stepData.light) {
            for (var i = 0; i < stepData.light.length; i++) {
                var x = stepData.light[i][0];
                var y = stepData.light[i][1];

                var cell = game.field.getCell(x, y);
                while (cell) {
                    moveUpNodes.push(cell.onGetView());
                    cell = cell.innerCell;
                }

                var tile = game.field.getFloorTile(x, y);
                if (tile) {
                    var hideTile = tile instanceof RabbitTile && tile.size === 2 && tile.isRoot && stepData && stepData.cellBooster;
                    if (!hideTile) {
                        moveUpNodes.push(tile.onGetView());
                    }
                }

                moveUpNodes.push(game.field.onGetFloorBackgroundView(x, y));
                moveUpNodes = moveUpNodes.concat(game.field.onGetBarriersViews(x, y));
            }
        }

        if (stepData.moves) {
            moveUpNodes.push(game.onGetMovesView());
        }

        if (stepData.booster !== undefined) {
            moveUpNodes.push(game.boosters.onGetBoosterView(stepData.booster));
        }

        if (stepData.goals || stepData.goalsForce) {
            moveUpNodes.push(game.goals.onGetView());
        }

        return moveUpNodes.filter(Boolean);
    },

    afterResize: function () {
        this.prepareData();
        this.createDialogue();
    }
});

LevelTutorialView.SELECTION_ZORDER = FieldView.TUTORIAL_BARRIER_ZORDER + 1;
LevelTutorialView.DIALOGUE_ZORDER = FieldView.TUTORIAL_BARRIER_ZORDER + 2;