MissionAnimationCloverView.prototype.getIcon = function () {
    return bundles.tournament_icon.frames.fly_clover_png;
};

MissionAnimationView.SimpleFlyAlgorithm = function (node, params) {
    var beginPosition = params.beginPos;
    node.setPosition(beginPosition);
    node.setScale(0);
    node.setOpacity(255);

    var l = cleverapps.styles.MissionAnimationView.bezierLength;
    var d = Math.random() * l;
    if (d < l / 2) {
        d -= l;
    }
    var targetPosition = cc.p(beginPosition.x + d, beginPosition.y - 2 * l);
    var bezierSecondPoint = cc.p(targetPosition.x, targetPosition.y + l);
    var bezierFirstPoint = cc.p(beginPosition.x, beginPosition.y + l * (1.5 + Math.random() * 0.5));

    var bezierPoints = [bezierFirstPoint, bezierSecondPoint, targetPosition];

    var dt = 0.2;
    if (dt * params.amount > 3) {
        dt = 3 / params.amount;
    }

    node.runAction(new cc.Sequence(
        new cc.DelayTime(params.id * dt),
        new cc.CallFunc(function () {
            cleverapps.audio.playSound(bundles.game.urls.boom_effect, { throttle: 20 });
        }),
        new cc.Spawn(new cc.ScaleTo(0.5, 1.2), new cc.BezierTo(1.3, bezierPoints).easing(cc.easeIn(1.5)), new cc.RotateBy(1.3, d < 0 ? -500 : 500), new cc.Sequence(new cc.DelayTime(1), new cc.FadeOut(0.3))),
        new cc.CallFunc(function () {
            cc.pool.putInPool(node);
        })
    ));
};