/**
 * Created by decipaliz on 22.05.2024.
 */

var PrizeCalendarWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        var prizeCalendar = this.prizeCalendar = new PrizeCalendar(mission);

        this._super({
            title: "PrizeCalendarWindow.Title",
            content: this.createContent(),
            name: "prizecalendarwindow",
            openSound: bundles.prizecalendar.urls.window_effect
        });
        this.createBg();

        prizeCalendar.on("chestAnimation", this.onChestAnimation.bind(this));
        prizeCalendar.on("completeMission", this.onCompleteMission.bind(this));
        prizeCalendar.on("ticketAnimation", this.onTicketAnimation.bind(this));

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PRIZECAL_WINDOW);
    },

    createContent: function () {
        var styles = cleverapps.styles.PrizeCalendarWindow;

        var timer = new Timer(this.mission.getTimeLeft(), this.close.bind(this), {
            timerBg: bundles.prizecalendar.frames.big_timer_bg_png,
            timerIcon: bundles.prizecalendar.frames.big_timer_png
        });

        var tiles = [];
        this.prizeCalendar.rewards.forEach(function (reward, i) {
            var row = Math.floor(i / styles.tilesInRow);
            if (row % 2 === 1) {
                tiles.splice(row * styles.tilesInRow, 0, { rewards: reward, i: i });
            } else {
                tiles.push({ rewards: reward, i: i });
            }
        });
        tiles = tiles.map(function (tile) {
            return new PrizeCalendarTileView(tile.i, this.prizeCalendar, tile.rewards);
        }.bind(this));

        this.tiles = tiles;

        var tilesLayout = new cleverapps.GridLayout(tiles, {
            columns: styles.tilesInRow,
            margin: styles.tiles.margin,
            padding: styles.tiles.padding
        });

        var content = [timer, tilesLayout];
        if (styles.timerAtBottom) {
            content.reverse();
        }

        return new cleverapps.Layout(content, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.windowPadding
        });
    },

    createBg: function () {
        var styles = cleverapps.styles.PrizeCalendarWindow;
        if (styles.rainbowBg) {
            var rainbowBg = new cc.Sprite(bundles.prizecalendar.frames.rainbow_bg);
            rainbowBg.setScale(styles.rainbowBg.scale);
            rainbowBg.setPositionRound(styles.rainbowBg);
            this.content.addChild(rainbowBg, -1);
            return;
        }

        if (styles.bg) {
            var outerBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.window_outer_bg);
            var innerBg = cleverapps.UI.createScale9Sprite(bundles.prizecalendar.frames.window_inner_bg);
            var decorTopLeft = new cc.Sprite(bundles.prizecalendar.frames.window_bg_decor1);
            var decorTopRight = new cc.Sprite(bundles.prizecalendar.frames.window_bg_decor2);
            var decorBottomLeft = new cc.Sprite(bundles.prizecalendar.frames.window_bg_decor3);
            var decorBottomRight = new cc.Sprite(bundles.prizecalendar.frames.window_bg_decor4);

            innerBg.setContentSize2(styles.bg.innerBg);
            outerBg.setContentSize2(styles.bg.outerBg);

            var patternSize = new cc.Sprite(bundles.prizecalendar.frames.window_bg_pattern).getContentSize();
            for (var y = 0; y <= styles.bg.innerBg.height - patternSize.height - 2; y += patternSize.height - 2) {
                for (var x = 0; x <= styles.bg.innerBg.width - patternSize.width - 2; x += patternSize.width - 2) {
                    var pattern = new cc.Sprite(bundles.prizecalendar.frames.window_bg_pattern);
                    pattern.setPositionRound({ align: "left", dx: styles.bg.pattern.x + x }, { align: "top", dy: styles.bg.pattern.y - y });
                    innerBg.addChild(pattern);
                }
            }

            innerBg.setPositionRound(styles.bg.innerBg);
            decorTopLeft.setPositionRound(styles.bg.decorTopLeft);
            decorTopRight.setPositionRound(styles.bg.decorTopRight);
            decorBottomLeft.setPositionRound(styles.bg.decorBottomLeft);
            decorBottomRight.setPositionRound(styles.bg.decorBottomRight);

            outerBg.addChild(innerBg);
            outerBg.addChild(decorTopLeft);
            outerBg.addChild(decorTopRight);
            outerBg.addChild(decorBottomLeft);
            outerBg.addChild(decorBottomRight);

            outerBg.setPositionRound(styles.bg.outerBg);
            this.content.addChild(outerBg, -1);
        }
    },

    createShadow: function () {
        var shadow = cleverapps.UI.createScale9Sprite(bundles.windows.frames.window_bg_png);
        shadow.setColor(cleverapps.styles.COLORS.BLACK);
        shadow.setOpacity(0);

        var padding = cleverapps.styles.PrizeCalendarWindow.shadow.padding;
        var rect = this.getContentSize();
        shadow.setContentSize(rect.width + 2 * padding, rect.height + 2 * padding);

        shadow.setPositionRound(this.getPosition());
        shadow.setLocalZOrder(1);
        this.addChild(shadow);

        return shadow;
    },

    onChestAnimation: function (rewards) {
        this.createShadow().runAction(new cc.Sequence(
            new cc.FadeTo(0.5, 100),
            new cc.CallFunc(function () {
                var prize = new cleverapps.Spine(bundles.prizecalendar.jsons.prize_json);
                prize.setSkin("chest5");
                prize.setPositionRound({ align: "center" }, { align: "center" });
                prize.setLocalZOrder(1);
                prize.setAnimation(0, "open_chest", false);
                this.addChild(prize);

                cleverapps.audio.playSound(bundles.prizecalendar.urls.reward_effect);

                prize.setCompleteListenerOnce(function () {
                    prize.setAnimation(0, "reward", false);

                    rewards.collectRewardsAnimation(prize, {
                        flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,
                        allAtOnce: true,
                        small: false,
                        beginScale: 1
                    });

                    this.runAction(new cc.Sequence(
                        new cc.DelayTime(cleverapps.styles.PrizeCalendarWindow.prizeDelay),
                        new cc.CallFunc(function () {
                            this.close();
                        }.bind(this))
                    ));
                }.bind(this));
            }.bind(this))
        ));
    },

    onCompleteMission: function () {
        this.close();
    },

    onTicketAnimation: function (callback, rewards) {
        var styles = cleverapps.styles.PrizeCalendarWindow.ticketAnimation;
        callback = cleverapps.once(callback);

        var shadow = this.createShadow();
        shadow.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.FadeTo(0.2, 100),
                new cc.CallFunc(function () {
                    var ticketBg = this.ticketBg = cleverapps.UI.createSprite(bundles.prizecalendar.frames.ticket_animation_bg);
                    ticketBg.setLocalZOrder(1);
                    ticketBg.setPositionRound({ align: "center" }, { align: "center" });
                    this.addChild(ticketBg);

                    var ticketAnimation = new cleverapps.Spine(bundles.prizecalendar.jsons.ticket_animation_json);

                    ticketAnimation.setPositionRound(styles);
                    ticketAnimation.setLocalZOrder(1);
                    ticketAnimation.setAnimation(0, "animation", false);
                    ticketAnimation.setCompleteListenerRemove();

                    ticketBg.addChild(ticketAnimation);

                    var rewardNode = this.rewardNode = new RewardsListComponent(rewards, {
                        columns: 1,
                        font: cleverapps.styles.FONTS.PRIZE_CALENDAR
                    });
                    rewardNode.setPositionRound(styles.rewardNode);
                    ticketBg.addChild(rewardNode);

                    cleverapps.audio.playSound(bundles.prizecalendar.urls.reward_effect);

                    ticketBg.setScale(0);
                    ticketBg.runAction(new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut()));
                }.bind(this))
            ),
            new cc.DelayTime(cleverapps.styles.PrizeCalendarWindow.ticketAnimation.delay.reward),
            new cc.CallFunc(function () {
                this.rewardNode.receiveRewardsAnimation({
                    duration: 0.2
                });
            }.bind(this)),
            new cc.DelayTime(styles.delay.animation),
            new cc.Spawn(
                new cc.FadeOut(0.2),
                new cc.CallFunc(function () {
                    this.rewardNode.removeFromParent();
                    delete this.rewardNode;

                    this.ticketBg.removeFromParent();
                    delete this.ticketBg;

                    callback();
                }.bind(this))
            ),
            new cc.RemoveSelf()
        ));
    },

    listBundles: function () {
        return ["prizecalendar"];
    }
});

cleverapps.styles.PrizeCalendarWindow = {
    margin: 30,
    tilesInRow: 3,
    windowPadding: {
        bottom: 40
    },

    tiles: {
        margin: {
            x: 40, y: 50
        },
        padding: {
            x: 20
        }
    },
    timer: {
        width: 250,
        height: 50
    },
    button: {
        width: 300,
        height: 120
    },
    prizeDelay: 3,
    shadow: {
        x: { align: "center" },
        y: { align: "center" },

        padding: 20
    }
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PRIZE_CALENDAR: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});
