/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.keys = {
    parse: function (value, options) {
        return Object.keys(value).filter(function (name) {
            return value[name];
        }).map(function (name) {
            return new Reward("keys", { name: name, amount: value[name] }, options);
        });
    },

    handler: function () {
        return function () {};
    }
};
