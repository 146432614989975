/**
 * Created by iamso on 08.11.18.
 */

var Panel = cc.Node.extend({
    avoidNode: "Panel",

    ctor: function (type) {
        this._super();

        this.type = type;

        switch (type) {
            case "info":
                this.styles = cleverapps.styles.InfoPanel;
                break;
            case "controls":
                this.styles = cleverapps.styles.ControlsPanel;
                break;
        }

        if (this.dangerStyle) {
            this.styles = cleverapps.styles.DangerInfoPanel;
        }

        this.setAnchorPoint(0.5, 0.5);
        this.setVisible(false);

        this.setPositionRound(this.getBasePosition());
        this.updateSize();
    },

    getDecors: function () {
        if (!this.styles.decors) {
            return;
        }

        return [{
            image: bundles.game.frames.goals_background_decor_right,
            position: this.styles.decors.right
        }, {
            image: bundles.game.frames.goals_background_decor_left,
            position: this.styles.decors.left
        }];
    },

    getBgStyles: function () {
        var image;
        var rotations;
        var decors;

        switch (this.type) {
            case "info":
                image = bundles.game.frames.goals_background;
                rotations = [0, 0, -90];
                decors = this.getDecors();
                break;
            case "controls":
                rotations = [0, -90, -90];
                image = bundles.game.frames.boosters_background_png || bundles.game.frames.goals_background;
                break;
        }

        if (this.dangerStyle) {
            rotations = undefined;
            image = bundles.danger_component.frames.top_bg;
        }

        return {
            image: image,
            rotations: rotations,
            scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
            decors: decors,
            position: this.styles.backgroundPosition
        };
    },

    calcHidePosition: function () {
        var scene = cleverapps.scenes.getRunningScene();
        switch (this.orientation) {
            case Panel.UP:
                return cc.p(this.x, scene.height + this.height / 2);
            case Panel.DOWN:
                return cc.p(this.x, -scene.height - this.height / 2);
            case Panel.LEFT:
                return cc.p(-scene.width - this.width / 2, this.y);
            case Panel.RIGHT:
                return cc.p(scene.width + this.width / 2, this.y);
        }
    },

    show: function (silent) {
        if (silent) {
            this.stopAllActions();
            this.setVisible(true);
            return;
        }

        if (this.hidden || this.isVisible()) {
            return;
        }

        var basePosition = this.calculatePositionRound(this.getPosition());
        this.setPosition(this.calcHidePosition());

        this.stopAllActions();

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(this.styles.delay),
                new cc.Show(),
                new cc.MoveTo(0.6, basePosition).easing(cc.easeElasticOut(1.5))
            )
        ).setFinalize(function () {
            this.setPosition(basePosition);
            this.visible = true;
        }.bind(this));
    },

    hide: function () {
        var hidePosition = this.calcHidePosition();

        this.runAction(
            new cc.Sequence(
                new cc.MoveTo(0.15, hidePosition).easing(cc.easeIn(2)),
                new cc.CallFunc(function () {
                    this.visible = false;
                    this.hidden = true;
                }.bind(this))
            )
        ).setFinalize(function () {
            this.visible = false;
            this.hidden = true;
        }.bind(this));
    },

    completeAnimationOnResize: function () {
        this.stopAllActions();
    },

    updateSize: function () {
        if (this.wideMode !== cleverapps.resolution.mode) {
            this.wideMode = cleverapps.resolution.mode;

            this.orientation = this.getOrientation();
            this.setContentSize2(this.styles.sizes[cleverapps.resolution.mode]);
            this.baseScale = !cleverapps.config.rpg && cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL ? 1.2 : 1;
            if (this.styles.scales && this.styles.scales[cleverapps.resolution.mode]) {
                this.baseScale = this.styles.scales[cleverapps.resolution.mode];
            }

            this.setScale(this.baseScale);

            this.updateBackground();
        }

        if (!this.parent) {
            return;
        }

        var scene = cleverapps.scenes.getRunningScene();
        var margin = this.styles.margins;

        if (margin && [Panel.UP, Panel.DOWN].includes(this.orientation)) {
            margin = Array.isArray(margin) ? margin[cleverapps.resolution.mode] : margin;

            var availableWidth = Math.min(this.getPosition().x, scene.width - this.getPosition().x);
            availableWidth = Math.max(availableWidth - margin, 0);

            var currentWidth = this.width * this.baseScale / 2;

            var scale = Math.min(1, availableWidth / currentWidth);
            this.setScale(this.baseScale * scale);
        }

        this.setPositionRound(this.getBasePosition());
    },

    updateBackground: function () {
        if (this.background) {
            this.background.removeFromParent(true);
            this.background.decors && this.background.decors.forEach(function (decor) {
                decor.removeFromParent(true);
            });
        }

        var bgStyles = this.getBgStyles();

        if (!bgStyles.image) {
            return;
        }

        bgStyles.width = this.width;
        bgStyles.height = this.height;

        var background = this.background = cleverapps.UI.createSprite(bgStyles);
        var position = bgStyles.position ? bgStyles.position[cleverapps.resolution.mode] : { x: this.width / 2, y: this.height / 2 };
        this.addChild(background, -1);
        background.setPositionRound(position);
        this.background.decors = [];

        if (bgStyles.decors) {
            bgStyles.decors.forEach(function (decor) {
                var image = new cc.Sprite(decor.image);

                image.setPositionRound(decor.position);

                background.addChild(image);

                if (background.rotation) {
                    image.setRotation(background.rotation);
                }

                var pos = this.convertToNodeSpace(image.parent.convertToWorldSpace(image.getPosition()));

                this.background.decors.push(image);
                image.replaceParent(this);
                image.setPositionRound(pos);
            }, this);
        }
    }
});

var ControlsPanel = Panel.extend({
    ctor: function () {
        this._super("controls");
        this.setLocalZOrder(ControlsPanel.ZORDER);

        if (cleverapps.restoreProgress.isAvailable()) {
            this.avoidNode = undefined;
        }
    },
    getOrientation: function () {
        return ControlsPanel.IS_DOWN() ? Panel.DOWN : Panel.RIGHT;
    },
    getBasePosition: function () {
        return this.styles.position[cleverapps.resolution.mode];
    }
});

ControlsPanel.IS_DOWN = function () {
    return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
};

ControlsPanel.ZORDER = 10;

var InfoPanel = Panel.extend({
    ctor: function () {
        this.dangerStyle = Game.currentGame.hasDanger();

        this._super("info");
        this.setLocalZOrder(InfoPanel.ZORDER);
    },
    getOrientation: function () {
        if (this.dangerStyle || cleverapps.resolution.mode !== cleverapps.WideMode.HORIZONTAL) {
            return Panel.UP;
        }

        return Panel.LEFT;
    },
    getBasePosition: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var position = this.styles.position[cleverapps.resolution.mode];

        if (this.dangerStyle && scene.dangerAnimationView && cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            position.x = scene.width * scene.getHorizontalCenterPartOfScreen();
        }

        return position;
    }
});

InfoPanel.ZORDER = 6;

Panel.UP = 0;
Panel.RIGHT = 1;
Panel.DOWN = 2;
Panel.LEFT = 3;

cleverapps.styles.InfoPanel = {
    delay: 0,

    position: [
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "center" },
            y: { align: "top" }
        },
        {
            x: { align: "left" },
            y: { align: "center" }
        }
    ],

    sizes: [
        {
            width: 675,
            height: 215
        },
        {
            width: 675,
            height: 205
        },
        {
            width: 205,
            height: 642
        }
    ],

    decors: {
        left: {
            x: { align: "left", dx: -40 },
            y: { align: "top", dy: 15 }
        },
        right: {
            x: { align: "right", dx: 57 },
            y: { align: "top" }
        }
    },

    margins: 48
};

cleverapps.styles.ControlsPanel = {
    delay: 3,

    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 0 }
        },
        {
            x: { align: "right", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        {
            x: { align: "right", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    ],

    sizes: [
        {
            width: 550,
            height: 210
        },
        {
            width: 195,
            height: 550
        },
        {
            width: 195,
            height: 550
        }
    ],

    margins: 120
};

cleverapps.styles.DangerInfoPanel = {
    delay: 2,

    position: [
        {
            x: { align: "center" },
            y: { align: "center", dy: 70 }
        },
        {
            x: { align: "center" },
            y: { align: "center", dy: 70 }
        },
        {
            x: { align: "right", dx: -100 },
            y: { align: "top", dy: -20 }
        }
    ],

    sizes: [
        {
            width: 720,
            height: 175
        },
        {
            width: 720,
            height: 175
        },
        {
            width: 720,
            height: 175
        }
    ],

    scales: [0.8, 0.8, 1.2],

    margins: [0, 0, 200]
};