/**
 * Created by mac on 10/22/18
 */

HeroesLibrary.byColors = cleverapps.overrideStyles(HeroesLibrary.byColors, {
    e: {
        shootAlgorithm: "rocket",
        shoot_animation: bundles.heroes.jsons.E_hero_spine_effect_json,

        action_timeout: 1500,
        action_delay: 600,
        between_shoot_timeout: 100,

        blast_animation: bundles.game.jsons.firework_blast_json
    },

    d: {
        action_timeout: 1000,
        action_delay: 800,

        bigZOrder: false,

        blast_animation: bundles.game.jsons.firework_blast_json
    },

    b: {
        shootFlyDuration: function () {
            return 1.5;
        },

        action_timeout: 1500,
        action_delay: 375,

        shootAlgorithm: "throw",

        blast_animation: bundles.heroes.jsons.B_hero_spine_effect_json
    },

    w: {
        action_timeout: 900,
        action_delay: 300
    },

    c: {
        action_timeout: 1300,
        action_delay: 900
    },

    a: {
        action_timeout: 1700
    }
});