/**
 * Created by vlad on 17.10.18.
 */

FireworkCell.prototype.smile = function () {
    if (this.alive) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};

FireworkCell.MIN_FLY_DURATION = 0.8;