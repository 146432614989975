/**
 * Created by decipaliz on 22.05.2024
 */

var PrizeCalendar = function (mission) {
    cleverapps.EventEmitter.call(this);
    this.mission = mission;

    this.initializeRewards();
};

PrizeCalendar.prototype = Object.create(cleverapps.EventEmitter.prototype);
PrizeCalendar.prototype.constructor = PrizeCalendar;

PrizeCalendar.prototype.getClaimableTile = function () {
    return this.mission.result;
};

PrizeCalendar.prototype.getTileType = function (tileIndex) {
    if (tileIndex >= this.getTilesAmount()) {
        return;
    }
    if (tileIndex < this.getClaimableTile()) {
        return PrizeCalendarTileView.TYPE_RECEIVED;
    }
    if (tileIndex > this.getClaimableTile()) {
        return PrizeCalendarTileView.TYPE_LOCKED;
    }

    var stage = RewardsConfig.PrizeCalendar.stages[tileIndex];
    if (stage.free) {
        return PrizeCalendarTileView.TYPE_FREE;
    }
    if (stage.ads) {
        return this.timeUntilNextAd() > 0 ? PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE : PrizeCalendarTileView.TYPE_ADS_AVAILABLE;
    }
    if (stage.hard) {
        return PrizeCalendarTileView.TYPE_HARD;
    }
};

PrizeCalendar.prototype.getTilePrice = function (tileIndex) {
    return RewardsConfig.PrizeCalendar.stages[tileIndex] && RewardsConfig.PrizeCalendar.stages[tileIndex].hard;
};

PrizeCalendar.prototype.onClaimTile = function () {
    this.rewards[this.getClaimableTile()].receiveRewards();
    this.trigger("receiveTile", this.getClaimableTile());
    this.mission.update(1);
    if (this.getClaimableTile() < this.getTilesAmount()) {
        if ([PrizeCalendarTileView.TYPE_ADS_AVAILABLE, PrizeCalendarTileView.TYPE_ADS_UNAVAILABLE].indexOf(this.getTileType(this.getClaimableTile())) !== -1) {
            this.mission.update(0, { adTimerStarted: Date.now() });
        }
        this.trigger("changeType", this.getClaimableTile());
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PRIZECAL_STAGE + "_" + this.getClaimableTile());

    this.mission.logic.checkCompletion();
};

PrizeCalendar.prototype.timeUntilNextAd = function () {
    var cooldown = cleverapps.config.debugMode ? cleverapps.parseInterval("20 seconds") : cleverapps.parseInterval(PrizeCalendar.ADS_COOLDOWN);
    return (this.mission.details.adTimerStarted || 0) + cooldown - Date.now();
};

PrizeCalendar.prototype.resetAdTimer = function () {
    this.mission.update(0, {});
};

PrizeCalendar.prototype.isWaitInsteadOfAds = function () {
    return !cleverapps.rewardedAdsManager.isEnabled();
};

PrizeCalendar.prototype.onShowAds = function () {
    if (this.timeUntilNextAd() > 0) {
        return;
    }
    if (this.isWaitInsteadOfAds()) {
        this.onClaimTile();
    } else {
        cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.PRIZE_CALENDAR, this.onClaimTile.bind(this));
    }
};

PrizeCalendar.prototype.onSpendHard = function () {
    if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.PRIZE_CALENDAR, this.getTilePrice(this.getClaimableTile()))) {
        this.onClaimTile();
    }
};

PrizeCalendar.prototype.getStageReward = function (stage) {
    return RewardsConfig.PrizeCalendar.stages[stage].reward;
};

PrizeCalendar.prototype.initializeRewards = function () {
    this.rewards = [];
    for (var i = 0; i < this.getTilesAmount(); i++) {
        this.rewards.push(new RewardsList(this.getStageReward(i), { event: cleverapps.EVENTS.EARN.MISSION }));
    }
};

PrizeCalendar.prototype.getTilesAmount = function () {
    return this.mission.logic.getTilesAmount();
};

PrizeCalendar.ADS_COOLDOWN = "20 minutes";