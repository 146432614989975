/**
 * Created by vladislav on 1/29/19
 */

TransporterTileView.TRANSFORMS = {
    "UR": { scaleX: 1, scaleY: 1 },
    "UL": { scaleX: -1, scaleY: 1 },

    "DR": { scaleX: 1, scaleY: -1 },
    "DL": { scaleX: -1, scaleY: -1 },

    "RU": { scaleX: 1, scaleY: -1, angle: -90 },
    "RD": { scaleX: 1, scaleY: 1, angle: 90 },

    "LU": { scaleX: -1, scaleY: -1, angle: 90 },
    "LD": { scaleX: -1, scaleY: 1, angle: -90 }
};
