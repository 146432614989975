/**
 * Created by vladislav on 1/16/19
 */

TruffleCellView.prototype.positionAnimation = function () {
    var styles = cleverapps.styles.TruffleCellView;
    var imageToHide = undefined;
    if (this.images && this.images.length > 0) {
        var index = TruffleCell.LIVES - (this.cell.lives + 1);
        imageToHide = this.images[index];
        var pos = this.images[0].getPosition();
        this.animation.setPositionRound(pos.x, pos.y + styles.animation.offsetY);
    }
    return imageToHide;
};

TruffleCellView.prototype.positionImage = function (sprite, position) {
    var styles = cleverapps.styles.TruffleCellView;

    sprite.setPosition(this.width / 2, this.height / 2 + (position) * styles.images[position].offsetY + styles.topOffsetY[this.cell.lives]);
    sprite.setScale(styles.images[position].scale);
};

cleverapps.overrideStyles(cleverapps.styles.TruffleCellView, {
    images: {
        "0": {
            offsetY: 10,
            scale: 1.0
        },
        "1": {
            offsetY: -15,
            scale: 0.95
        },
        "2": {
            offsetY: -15,
            scale: 0.9
        }
    },
    rose: {
        x: 5,
        y: 17,
        jumpHeight: 50
    },
    animation: {
        offsetY: 12
    },
    topOffsetY: {
        1: 0,
        2: 5,
        3: 10
    }
});