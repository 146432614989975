/**
 * Created by vlad on 23.11.18.
 */

BurstCellView.prototype.burstAnimation = function (targetCell, id) {
    var ball = new cc.Sprite(bundles.burst.frames.spike);
    ball.setOpacity(0);
    this.parent.addChild(ball);
    ball.setAnchorPoint(0.5, 0.5);
    ball.setPosition(this.getPosition());
    var FADE_TIME = 0.1;

    var delayTime = this.cell.beforeBurstDuration();
    var burstDuration = this.cell.burstDuration(targetCell, id);

    var targetPosition = BaseCellView.alignInTheGrid(targetCell.x, targetCell.y);
    this.rotateBall(ball, targetPosition);
    ball.runAction(new cc.Sequence(
        new cc.DelayTime(delayTime),
        new cc.Spawn(
            new cc.FadeIn(FADE_TIME),
            new cc.MoveTo(burstDuration - delayTime, targetPosition).easing(cc.easeQuadraticActionIn()),
            new cc.Sequence(
                new cc.ScaleTo((burstDuration - delayTime) / 2, 1.8),
                new cc.ScaleTo((burstDuration - delayTime) / 2, 1.3)
            ),
            new cc.Sequence(
                new cc.DelayTime(burstDuration - delayTime - FADE_TIME),
                new cc.FadeOut(FADE_TIME)
            )
        ),
        new cc.RemoveSelf()
    ));
};

BurstCellView.prototype.changeLives = function () {
    if (this.cell.lives > 0) {
        if (this.cell.lives < BurstCell.MAX_LIVES) {
            this.animate("" + (BurstCell.MAX_LIVES - this.cell.lives), false, this.finishAnimation.bind(this));
            cleverapps.audio.playSound(bundles.burst.urls.cactus_hit_effect);
        } else {
            this.updateImage();
        }
    } else {
        this.animate("4_idle", true, function () {});
    }
};

BurstCellView.prototype.swapImageToAnimation = function () {
    this.runAction(new cc.CallFunc(function () {
        this.image.visible = false;
        this.animation.visible = true;
    }.bind(this)));
};

BurstCellView.prototype.finishSmile = function () {
    if (this.smiling) {
        this.smiling = false;
        this.image.visible = true;
        this.animation.visible = false;
    }
};

BurstCellView.prototype.smile = function () {
    if (!this.animation) {
        return;
    }

    if (this.smiling) {
        return;
    }

    if (!this.cell.lives) {
        return;
    }

    this.smiling = true;
    this.animation.setAnimation(0, "smile_" + (BurstCell.MAX_LIVES - this.cell.lives), false);
    this.animation.setCompleteListener(this.finishSmile.bind(this));
    this.swapImageToAnimation();
};

BurstCellView.prototype.rotateBall = function (ball, targetPosition) {
    ball.setRotation(-Math.atan2(targetPosition.y - ball.y, targetPosition.x - ball.x) * 180 / Math.PI + BurstCellView.BaseRotation);
};

BurstCellView.BaseRotation = 30;

cleverapps.overrideStyles(cleverapps.styles.BurstCellView, {
    openSound: {
        delay: 0.5
    }
});