/**
 * Created by slava on 14/8/17
 */

var AvailableMoveView = cc.Node.extend({
    ctor: function (availableMove) {
        this._super();

        availableMove.on("changeVisible", this.setVisible.bind(this), this);
    },

    setVisible: function (visible, move) {
        if (visible) {
            cleverapps.audio.playSound(bundles.game.urls.available_move_effect);
        }

        var cellA, cellB;

        if (!Game.currentGame || !Game.currentGame.field || !Game.currentGame.field.cells) {
            return;
        }

        var cells = [];
        if (move.shape) {
            cells = move.shape.cells;
        } else {
            cellA = move.start;
            cellB = Game.currentGame.field.cells[cellA.y + move.dir.row] && Game.currentGame.field.cells[cellA.y + move.dir.row][cellA.x + move.dir.col];
            if (!cellA || !cellB) {
                return;
            }
            cells.push(cellA);
            cells.push(cellB);
        }

        if (visible) {
            cells.forEach(function (cell) {
                (cell.innerCell || cell).onAvailableMoveListener(move);
            });
        } else {
            cells.forEach(function (cell) {
                (cell.innerCell || cell).onStartMoveListener({ x: cell.x, y: cell.y }, { moveInterval: 0.1, easing: cc.easeOut(1) });
            });
        }
    }
});
