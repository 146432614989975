/**
 * Created by Oleg on 18.02.18.
 */

FireflyJarCellView.FLY_TO_GOAL_DURATION = 0.88;

FireflyJarCellView.prototype.updateAnimation = function () {
    if (this.cell.lives < 1 || !this.cell.alive) {
        return;
    }
    this.animation.setCompleteListener();
    this.animation.setAnimation(0, this.cell.lives > 5 ? "idle1" : "idle" + (6 - this.cell.lives), true);
};

FireflyJarCellView.prototype.hurtAnimation = function () {
    var lives = this.cell.lives;
    if (this.cell.lives >= 5) {
        lives = 4;
    }
    var animation = "open" + (5 - lives);
    this.setLocalZOrder(9);
    this.animation.setAnimation(0, animation, false);
    this.animation.setCompleteListener(function () {
        this.updateAnimation();
        this.setLocalZOrder(this.baseZOrder);
    }.bind(this));
};