/**
 * Created by decipaliz on 12.03.2024
 */

Match3.prototype.showScreen = function (f, silent) {
    this.boosters.trigger("show", true);
    this.trigger("showPanels", silent);

    cleverapps.focusManager.compound(f, [
        function (f) {
            if (silent) {
                f();
                return;
            }

            this.trigger("startFilmEffect", {
                scale: 1.1,
                layerTimeout: 0.2,
                scaleTimeout: 0.8,
                moveBy: cleverapps.styles.GameScene.startAnimation.moveBy[cleverapps.resolution.mode]
            });

            f();
        }.bind(this),

        function (f) {
            this.goals.trigger("showUpAnimationStart", f, silent);
        }.bind(this),

        function (f) {
            this.trigger("stopFilmEffect");
            f();
        }.bind(this),

        function (f) {
            this.field.trigger("showUpFloor", f, silent);
        }.bind(this),

        function (f) {
            this.field.showUpCells(f, silent);
        }.bind(this)
    ]);
};
