/**
 * Created by slava on 3/5/19
 */

cleverapps.styles.comicses = {
    begin: {
        stages: [
            {
                showAnimation: "1",
                idleAnimation: "1_idle",
                dialogueStages: 2
            },
            {
                showAnimation: "2",
                idleAnimation: "2_idle"
            },
            {
                showAnimation: "3",
                idleAnimation: "3_idle"
            },
            {
                showAnimation: "4"
            }
        ],

        dialogue: [
            {
                text: "Comics.Begin.Text1",
                person: {
                    role: "white",
                    emotion: "idle",
                    orientation: "right"
                }
            },
            {
                text: "Comics.Begin.Text2",
                person: {
                    role: "red",
                    emotion: "idle"
                }
            },
            {
                text: "Comics.Begin.Text3",
                person: {
                    role: "green",
                    emotion: "idle"
                }
            },
            {
                text: "Comics.Begin.Text4",
                person: {
                    role: "purple",
                    emotion: "idle",
                    orientation: "right"
                }
            }
        ],

        bundles: ["comicses"],
        json: bundles.comicses.jsons.begin_json,
        onFinish: "game"
    }
};
