/**
 * Created by ivan on 06.03.18.
 */

BombByLeftMoves.ChooseLocations = function (field, amount) {
    var rows = [], cols = [], row, col;
    for (row = 0; row < Field.SIZE; ++row) {
        var emptyRow = true;
        for (col = 0; col < Field.SIZE; ++col) {
            if (field.isCellInField(row, col)) {
                emptyRow = false;
                break;
            }
        }
        if (!emptyRow) {
            rows.push(row);
        }
    }
    for (col = 0; col < Field.SIZE; ++col) {
        var emptyCol = true;
        for (row = 0; row < Field.SIZE; ++row) {
            if (field.isCellInField(row, col)) {
                emptyCol = false;
                break;
            }
        }
        if (!emptyCol) {
            cols.push(col);
        }
    }

    var amountMoves = Math.min(amount, Field.SIZE);

    cleverapps.shuffle(rows);
    cleverapps.shuffle(cols);

    var positions = [];

    while (amountMoves && (rows.length || cols.length)) {
        if (cols.length === 0 || rows.length && Math.random() < 0.5) {
            positions.push({ row: rows[0], col: -1 });
            rows.splice(0, 1);
        } else {
            positions.push({ row: -1, col: cols[0] });
            cols.splice(0, 1);
        }
        amountMoves--;
    }

    return positions;
};

BombByLeftMoves.prototype.go = function () {
    this.reverse = Math.random() < 0.5;

    this.onAnimateListener();

    var hurtCell = function (row, col) {
        if (Game.currentGame.field.isCellInField(row, col) && Game.currentGame.field.cells[row][col].alive) {
            Game.currentGame.field.cells[row][col].hurt({
                id: 0,
                groupSize: 1
            });
        }
    };

    var start = this.reverse ? Field.SIZE - 1 : 0;
    var end = this.reverse ? -1 : Field.SIZE;
    var sign = this.reverse ? -1 : +1;

    var hurtDelay = function (index) {
        return (index + 0.5) / BombByLeftMovesView.SPEED * 1000 + BombByLeftMovesView.DELAY;
    };

    var i, row, col;

    if (this.y === -1) {
        for (row = start, i = 0; row !== end; row += sign, ++i) {
            if (Game.currentGame.field.isCellInField(row, this.x)) {
                Game.currentGame.counter.setTimeout(hurtCell.bind(this, row, this.x), hurtDelay(i));
            }
        }
    } else {
        for (col = start, i = 0; col !== end; col += sign, ++i) {
            if (Game.currentGame.field.isCellInField(this.y, col)) {
                Game.currentGame.counter.setTimeout(hurtCell.bind(this, this.y, col), hurtDelay(i));
            }
        }
    }

    Game.currentGame.counter.setTimeout(function () {
        this.explode();
    }.bind(this), (Field.SIZE + 1) / BombByLeftMovesView.SPEED * 1000 + BombByLeftMovesView.DELAY);
};

BombByLeftMoves.prototype.explode = function () {
    this.onExplodeListener();
};