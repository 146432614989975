/**
 * Created by vladislav on 04.09.2019
 */

var LanternWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission, fromLevelStartWindow) {
        var styles = cleverapps.styles.LanternWindow;

        this.mission = mission;

        this._super({
            name: "lanternwindow",
            title: "LanternWindow.Title",
            content: this.getContent(),
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: "Play",
                onPressed: function () {
                    if (fromLevelStartWindow) {
                        this.close();
                        return;
                    }
                    cleverapps.focusManager.distract({
                        focus: "PlayButton",
                        action: function (f) {
                            this.close();
                            cleverapps.meta.wantsToPlay(f);
                        }.bind(this)
                    });
                }.bind(this)
            },
            styles: cleverapps.styles.LanternWindow.window,
            help: bundles.lantern_guide_window && function () {
                new GuideWindow({
                    name: "LanternGuideWindow",
                    bundle: bundles.lantern_guide_window
                });
            }
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.LanternWindow;

        var bgNode = new cc.Node();
        bgNode.setAnchorPoint(0.5, 0.5);

        var bg = new cc.Sprite(bundles.lantern_window.frames.lantern_bg_png);
        bgNode.addChild(bg);
        bgNode.setContentSize(bg.width * bg.scaleX, bg.height * bg.scaleY);
        bg.setPositionRound(bgNode.width / 2, bgNode.height / 2);

        var animation = new cleverapps.Spine(bundles.lantern.jsons.lantern_json);
        bgNode.addChild(animation);
        animation.setPositionRound(bgNode.width / 2 + styles.animation.x, bgNode.height / 2 + styles.animation.y);
        animation.setAnimation(0, "idle_" + this.mission.logic.lantern.getCurrentStreak(), true);

        var bonuses = this.createBonuses();
        bgNode.addChild(bonuses);
        bonuses.setPositionRound(styles.bonuses);

        var text = this.createText();

        if (this.mission.isRunning()) {
            var timer = this.createTimer();
        }

        var items = [bgNode, text];

        bgNode.addChild(timer);
        timer.setPositionRound(styles.timer);

        return new cleverapps.Layout(items, {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });
    },

    createBonuses: function () {
        var styles = cleverapps.styles.LanternWindow;

        var currentStreak = this.mission.logic.lantern.getCurrentStreak();

        var items = [];

        var boosters = cleverapps.boosters.listBoostersBefore();

        boosters.forEach(function (booster, index) {
            var isActive = index < currentStreak;
            var hasArrow = index < boosters.length - 1 && styles.bonuses.arrow;

            items.push(this.createBoosterBg(booster, isActive, hasArrow));

            var bundle = bundles.lantern_window.frames;
            if (index < boosters.length - 1 && !styles.bonuses.arrow) {
                var arrowName = "lantern_arrow_off_png";
                if (isActive || !bundle[arrowName]) {
                    arrowName = "lantern_arrow_png";
                }

                var arrow = new cc.Sprite(bundle[arrowName]);

                arrow.setLocalZOrder(1);
                items.push(arrow);

                if (styles.flashingArrows) {
                    var white = cleverapps.styles.COLORS.WHITE;
                    var darkGray = cleverapps.styles.COLORS.COLOR_DARK_GRAY;

                    if (isActive) {
                        if (index + 1 === currentStreak) {
                            arrow.runAction(new cc.RepeatForever(
                                new cc.Sequence(
                                    new cc.TintTo(0.9, darkGray.r, darkGray.g, darkGray.b),
                                    new cc.DelayTime(0.1),
                                    new cc.TintTo(0.9, white.r, white.g, white.b),
                                    new cc.DelayTime(0.1)
                                )
                            ));
                        }
                    } else {
                        arrow.setColor(darkGray);
                    }
                }
            }
        }.bind(this));

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.bonuses.margin
        });
    },

    createBoosterBg: function (booster, isActive, hasArrow) {
        var styles = cleverapps.styles.LanternWindow;

        var bg = new cc.Scale9Sprite(isActive ? bundles.lantern_window.frames.bonus_bg_on_png : bundles.lantern_window.frames.bonus_bg_off_png);
        bg.setContentSize2(styles.bonuses.bonusBg);

        var icon = new cc.Sprite(booster.icon);
        bg.addChild(icon);

        icon.setPositionRound(bg.width / 2, bg.height / 2);
        if (!isActive) {
            icon.setColor(new cc.Color(120, 120, 120, 255));
        }

        if (hasArrow) {
            var arrow = new cc.Sprite(isActive ? bundles.lantern_window.frames.bonus_arrow_on_png : bundles.lantern_window.frames.bonus_arrow_off_png);
            arrow.setPositionRound(styles.bonuses.arrow);
            bg.addChild(arrow);
        }

        return bg;
    },

    createText: function () {
        var styles = cleverapps.styles.LanternWindow;

        var text = cleverapps.UI.generateTTFText("LanternWindow.text", styles.text.font);
        text.setDimensions(styles.text.width, 0);
        text.fitTo(undefined, styles.text.height);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        return text;
    },

    createTimer: function () {
        return new Timer(this.mission.getTimeLeft(), this.close.bind(this), {
            timerBg: bundles.lantern_window.frames.big_timer_bg_png,
            timerIcon: bundles.lantern_window.frames.big_timer_png
        });
    },

    listBundles: function () {
        return ["lantern_window"];
    }
});

LanternWindow.BONUSES_ORDER = {
    "heroes": 0,
    "moves": 1,
    "multicolor": 2
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LANTERN_WINDOW_TEXT: {
        size: 33,
        color: new cc.Color(130, 70, 40, 255)
    },
    LANTERN_WINDOW_AMOUNT_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: {
            size: 2,
            color: cleverapps.styles.COLORS.BLACK
        }
    }
});

cleverapps.styles.LanternWindow = {
    margin: 30,
    timer: {
        x: { align: "center" },
        y: { align: "top", dy: 15 },
        bg: {
            width: 215,
            height: 65
        }
    },
    text: {
        height: 400,
        width: 600,
        font: cleverapps.styles.FONTS.LANTERN_WINDOW_TEXT
    },
    button: {
        width: 220,
        height: 110
    },
    animation: {
        x: 0,
        y: 58
    },
    bonuses: {
        x: { align: "center" },
        y: { align: "bottom", dy: 5 },
        bonusBg: {
            width: 180,
            height: 180
        },
        offsetY: 5,
        margin: -23,
        amount: {
            x: { align: "right", dx: -28 },
            y: { align: "bottom", dy: 17 }
        },
        arrow: undefined
    }
};