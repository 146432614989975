/**
 * Created by Denis Kuzin on 23 may 2024
 */

var CellsComponentsFactory = function (cell, Component, options) {
    switch (Component) {
        case GoalCoefComponent:
            return new GoalCoefComponent(cell);
        case ColorComponent:
            cell.movable = true;
            cell.putInPoolAvailable = true;
            return new ColorComponent(cell, options.color);
        case SpecialColorComponent:
            return new SpecialColorComponent(cell);
        case DecoratorComponent:
            return new DecoratorComponent(cell);
        case BigComponent:
            cell.movable = false;
            cell.hurtable = true;
            return new BigComponent(cell, options.cellClass, options.rows, options.cols);
        case EaterComponent:
            cell.hurtable = false;
            return new EaterComponent(cell, options.cellClass, options.rows, options.cols);
        case LiveLineComponent:
            return new LiveLineComponent(cell);
        case MuffinComponent:
            return new MuffinComponent(cell);
        case SpecialShapeComponent:
            cell.movable = false;
            cell.hurtable = false;
            return new SpecialShapeComponent(cell, options.cellClass);
        case MarkComponent:
            return new MarkComponent(cell);
        case ComboComponent:
            return new ComboComponent(cell);
    }
};