/**
 * Created by decipaliz on 14.03.2024
 */

FieldView.prototype.onBombByLeftMovesAnimation = function () {
    this.setLocalZOrder(12);
};

FieldView.prototype.bombByLeftMovesCreate = function (bomb) {
    var view = BombByLeftMovesView.factory(bomb);
    if (!view.parent) {
        var parent = this;
        if (cleverapps.styles.BombByLeftMovesView.parentScene) {
            parent = this.parent;
        }
        parent.addChild(view);
    }
};