/**
 * Created by slava on 01.08.17.
 */

var IceCreamMakerCell = function (x, y) {
    BaseCell.call(this, x, y);
    this.movable = false;
    Game.currentGame.counter.registerStage(201, IceCreamMakerCell.process);
};

IceCreamMakerCell.prototype = Object.create(BaseCell.prototype);
IceCreamMakerCell.prototype.constructor = IceCreamMakerCell;

IceCreamMakerCell.prototype.load = function (data) {
    this.lives = parseInt(data[1]);
};

IceCreamMakerCell.prototype.bundleId = function () {
    return "ice_cream_maker";
};

IceCreamMakerCell.prototype.save = function () {
    return IceCreamMakerCell.CODES.concat([this.lives]);
};

IceCreamMakerCell.prototype.getViewClass = function () {
    return IceCreamMakerCellView;
};

IceCreamMakerCell.prototype.hurt = function () {
    if (this.lives < 1) {
        return;
    }

    this.lives--;
    this.onChangeLivesListener();
    this.animate(BaseCell.ANIMATION_HURT);
};

IceCreamMakerCell.prototype.boom = function () {
    this.hurt();
};

IceCreamMakerCell.prototype.processTo = function (cell) {
    IceCreamMakerCell.changed[cell.y][cell.x] = true;
    var newIceCream;
    if (Game.currentGame.goals.hasType(IceCreamMakerCell.MAKE_COLOR)) {
        newIceCream = new BaseCell(cell.x, cell.y, {
            components: [ColorComponent, GoalCoefComponent],
            color: IceCreamMakerCell.MAKE_COLOR
        });
    } else {
        newIceCream = new BaseCell(cell.x, cell.y, {
            components: [ColorComponent],
            color: IceCreamMakerCell.MAKE_COLOR
        });
    }
    Game.currentGame.field.addCell(newIceCream);
    newIceCream.animate(BaseCell.ANIMATION_CREATE_FROM_CELL, { x: this.x, y: this.y });
    Game.currentGame.counter.setTimeout(function () {
        cell.delete();
    }, 900);
};

IceCreamMakerCell.prototype.process = function () {
    if (!Game.currentGame) {
        return;
    }

    var variants = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (!IceCreamMakerCell.changed[i][j] && Game.currentGame.field.cells[i][j]) {
                var currentCell = Game.currentGame.field.cells[i][j];
                var colorComponent = currentCell.findComponent(ColorComponent);
                if (currentCell.canDelete() && colorComponent && colorComponent.color !== IceCreamMakerCell.MAKE_COLOR) {
                    variants.push(currentCell);
                }
            }
        }
    }
    if (variants.length > 0) {
        for (i = 0; i < 4 && variants.length > 0; i++) {
            var vid = cleverapps.Random.random(variants.length);
            this.processTo(variants[vid]);
            variants.splice(vid, 1);
        }

        this.animate(BaseCell.ANIMATION_OPEN);

        Game.currentGame.counter.setTimeout(function () {
            this.lives = 4;
            this.onChangeLivesListener();
            this.animate(BaseCell.ANIMATION_HURT);
        }.bind(this), 1200);
    }
};

IceCreamMakerCell.process = function () {
    var toProcess = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j] && Game.currentGame.field.cells[i][j].constructor === IceCreamMakerCell) {
                if (Game.currentGame.field.cells[i][j].lives === 0) {
                    toProcess.push(Game.currentGame.field.cells[i][j]);
                }
            }
        }
    }

    if (toProcess.length > 0) {
        IceCreamMakerCell.changed = [];
        for (i = 0; i < Field.SIZE; i++) {
            IceCreamMakerCell.changed.push([]);
            for (j = 0; j < Field.SIZE; j++) {
                IceCreamMakerCell.changed.push(false);
            }
        }
        for (i = 0; i < toProcess.length; i++) {
            toProcess[i].process();
        }
    }
};

IceCreamMakerCell.MAKE_COLOR = "e";