/**
 * Created by decipaliz on 12.03.2024
 */

GoalsView.prototype.runStartAnimationAction = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var styles = cleverapps.styles.GoalsView.startAnimation;

    this.startAnimation.setScale(1);
    this.startAnimation.setPositionRound(styles.offset.x, scene.height / 2 + styles.offset.y);

    this.startAnimation.runAction(new cc.Sequence(
        new cc.DelayTime(0.1),
        new cc.MoveTo(0.7, scene.width / 2, this.startAnimation.y)
    ));
};
