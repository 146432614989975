/**
 * Created by vladislav on 2/26/19
 */

RugTileView.prototype.animateCreateNew = function () {
    this.animation.setAnimation(0, "animation", false);
};

RugTileView.prototype.setImage = function () {
    if (this.tile.isForGoal) {
        var image = new cc.Sprite(bundles.rug.frames.rug_goal);
        image.setPosition(this.width / 2, this.height / 2);
        this.image = image;
        this.addChild(image);
    } else {
        var animation = this.animation = new cleverapps.Spine(bundles.rug.jsons.rug_json);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);
        animation.setAnimation(0, "idle", false);
    }
};