/**
 * Created by vladislav on 22.01.2021
 */

var BonusWorld = function (mission, type) {
    this.mission = mission;
    this.mission.bonusWorld = this;
    this.type = type;

    this.config = BonusWorldConfig[this.type];
    this.bundle = bundles[this.config.bundle];
    this.levelsAmount = this.bundle.meta.episode.levelsAmount;

    cleverapps.EventEmitter.call(this);
};

BonusWorld.prototype = Object.create(cleverapps.EventEmitter.prototype);
BonusWorld.prototype.constructor = BonusWorld;

BonusWorld.prototype.backgroundBundleId = function () {
    if (connector.platform.oneOf(connector.INSTANT) && bundles[this.config.bundle + "_background_instant"]) {
        return this.config.bundle + "_background_instant";
    }

    return this.config.bundle + "_background";
};

BonusWorld.prototype.isFinished = function () {
    return this.mission.result === this.levelsAmount || !this.mission.isRunning();
};

BonusWorld.prototype.finish = function (cb) {
    this.mission.complete();

    cleverapps.focusManager.compound(cb, [
        function (f) {
            new TellWindow({
                text: "" + this.config.localizationPrefix + ".BonusWorldFinishWindow.Text",
                title: "" + this.config.localizationPrefix + ".BonusWorldFinishWindow.Title",
                buttonText: Messages.get("OK")
            });

            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this),
        function (f) {
            cleverapps.travelBook.gotoMainScene(f);
        }
    ]);
};

BonusWorld.prototype.onLevelPassed = function (level) {
    if (this.isCurrentLevel(level)) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BONUS_WORLD_LEVEL + "_" + this.type + "_" + this.getProgress());
        cleverapps.playSession.set(cleverapps.EVENTS.BONUS_WORLD_LEVEL_DAU, true);

        this.mission.update(1);
        this.levelPassed = true;
    }
};

BonusWorld.prototype.animateChangeProgress = function () {
    this.levelPassed = false;

    this.trigger("changeProgress");
};

BonusWorld.prototype.getProgress = function () {
    return this.mission.result;
};

BonusWorld.prototype.isCurrentLevel = function (level) {
    return level.levelNo === this.getProgress();
};

BonusWorld.prototype.getAvailableReward = function () {
    for (var i = 0; i < RewardsConfig.BonusWorld.length; i++) {
        if (this.getProgress() >= RewardsConfig.BonusWorld[i].level && !this.isRewardReceived(RewardsConfig.BonusWorld[i])) {
            return RewardsConfig.BonusWorld[i];
        }
    }
};

BonusWorld.prototype.isRewardReceived = function (reward) {
    return (this.mission.details.receivedRewards || {})[reward.level];
};

BonusWorld.prototype.giveReward = function (cb) {
    var reward = this.getAvailableReward();
    if (reward) {
        cleverapps.focusManager.compound(cb, [
            function (f) {
                this.trigger("giveReward", reward, f);
            }.bind(this),
            function (f) {
                new RewardWindow(reward.reward, { event: cleverapps.EVENTS.EARN.OTHER });
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BONUS_WORLD_REWARD + "_" + RewardsConfig.BonusWorld.indexOf(reward));

                var receivedRewards = this.mission.details.receivedRewards || {};
                receivedRewards[reward.level] = true;

                this.mission.update(0, Object.assign(this.mission.details, {
                    receivedRewards: receivedRewards
                }));

                f();
            }.bind(this)
        ]);
    } else {
        cb();
    }
};