/**
 * Created by slava on 10/8/18
 */

var HeroesScene = cleverapps.FixedWidthScene.extend({
    ctor: function () {
        this._super();

        var styling = cleverapps.clone(cleverapps.styles.HeroesScene.background);

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            styling.backgroundId = "heroes_bg_horizontal";
            styling.animation = bundles.heroes_scene.jsons.heroes_bg_horizontal_json;
        } else {
            styling.backgroundId = "heroes_bg_vertical";
            styling.animation = bundles.heroes_scene.jsons.heroes_bg_vertical_json;
        }
        this.styling = styling;
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_HEROES);

        match3.heroes.onHeroChangeLevel = this.createListener(this.heroBuildingUpgraded.bind(this));
        match3.heroes.onDisplayHeroForce = this.createListener(this.displayHeroForce.bind(this));

        this.heroBuildings = {};
        this.house = this.createHouse();
        this.addChild(this.house);
        cleverapps.styles.HeroesScene.heroBuildings.forEach(function (building) {
            var type = building.color;
            this.heroBuildings[type] = this.createHeroBuilding(building, match3.heroes.getLevel(type));
            this.house.addChild(this.heroBuildings[type]);
        }, this);

        cleverapps.exp.on("changeExp", this.createListener(this.updateAllInfo.bind(this)), this);

        this.customize();
        this.updateSize();

        cleverapps.placements.run(Placements.INTERMEDIATE);
    },

    updateBackgroundScale: function () {
        if (this.background) {
            this.background.setScale(Math.max(cleverapps.resolution.getBgSize().height / this.background.height, cleverapps.resolution.getBgSize().width / this.background.width));
        }
    },

    onPressed: function (building) {
        cleverapps.focusManager.display({
            stack: cleverapps.forces.isRunningForce(),
            focus: "HeroWindow",
            control: ["MenuBarGoldItem", "MenuBarExpItem"],
            action: function (f) {
                new HeroWindow({ color: building.hero_color });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    },

    createHouse: function () {
        if (!cleverapps.styles.HeroesScene.house.separateFromBg) {
            return new cc.Node();
        }
        var house = new cc.Node();
        var houseBg = new cc.Sprite(bundles.heroes_scene.urls.house);
        houseBg.setScale(this.height / houseBg.height);
        house.addChild(houseBg);
        return house;
    },

    updateSize: function () {
        this._super();

        if (this.house) {
            this.house.setPositionRound(cleverapps.styles.HeroesScene.house.position);
        }
    },

    createHeroBuilding: function (buildingStyles, level) {
        var building = new cc.Node();
        building.hero_color = buildingStyles.color;

        var styles = cleverapps.styles.HeroesScene;

        cleverapps.UI.onClick(building, this.onPressed.bind(this, building));

        var color = buildingStyles.color.toUpperCase();

        var animationNode = new cc.Node();
        animationNode.setAnchorPoint(0.5, 0.5);
        animationNode.setContentSize2(styles.heroBuilding.width, styles.heroBuilding.height);

        var animation = building.animation = new cleverapps.Spine(cleverapps.skins.getSlot(color + "_hero_spine_json") || bundles.heroes.jsons[color + "_hero_spine_json"]);
        animation.setScale(styles.heroBuilding.scale || 1.0);
        animation.setSkin(match3.heroes.getCurrentSkin());
        animation.setAnimation(0, "idle", true);
        animationNode.addChild(animation);
        animation.setPositionRound(animationNode.width / 2, animationNode.height / 2);

        var button = building.button = this.createButton(building);

        var content = new cleverapps.Layout([animationNode, button], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        building.setContentSize2(content.getContentSize());
        building.addChild(content);
        content.setPositionRound(building.width / 2, building.height / 2);

        var levelBlock = building.levelBlock = cleverapps.UI.createScale9Sprite(styles.levelBlock.bg);
        levelBlock.setContentSize2(styles.levelBlock.width, styles.levelBlock.height);
        levelBlock.setPositionRound(building.width / 2 + styles.levelBlock.x, building.height / 2 + styles.levelBlock.y);
        building.addChild(levelBlock);

        if (level === 0) {
            levelBlock.setVisible(false);
        }

        if (!match3.heroes.isHeroAvailable(building.hero_color)) {
            if (cleverapps.config.name === "adventure") {
                animation.setColor(new cc.Color(120, 120, 120, 255));
            } else {
                cleverapps.UI.convertToGrayScale(animation.spine);
            }
        }

        this.updateLevelText(levelBlock, level);
        building.setAnchorPoint(0.5, 0.5);
        building.setPositionRound(buildingStyles);

        return building;
    },

    createButton: function (building) {
        var color = building.hero_color;
        var canUpgrade = match3.heroes.canUpgradeHero(color);
        var level = match3.heroes.getLevel(color);
        var isMaxLvl = match3.heroes.isMaxLevel(color);

        var styles = cleverapps.styles.HeroesScene.buttons;

        var text, disabled;
        if (!match3.heroes.isHeroAvailable(color)) {
            var userLevel = levels.user.parseFloatLevel((Heroes.OPEN_HERO[color] && Heroes.OPEN_HERO[color].level) || Heroes.AVAILABLE.level);
            text = Messages.get("Available.level", {
                level: userLevel.episode * Level.LEVELS_PER_EPISODE + userLevel.level + 1
            });
            disabled = true;
        } else if (match3.heroes.isMaxLevel(color)) {
            text = "Heroes.Info.MaxLevel";
        } else if (canUpgrade) {
            text = level === 0 ? "Heroes.Unlock.Button" : "Heroes.Upgrade.Button";
        } else {
            text = "%%" + cleverapps.exp.getExp() + "/" + match3.heroes.expUpgradePrice(color);
        }

        return new cleverapps.UI.Button({
            disabled: disabled,
            text: text,
            icons: {
                "%%": bundles.reward_icons.frames.reward_exp_png
            },
            type: canUpgrade || isMaxLvl ? cleverapps.styles.UI.Button.Images.small_button_green : cleverapps.styles.UI.Button.Images.small_button_blue,
            width: styles.width,
            height: styles.height,
            onClicked: this.onPressed.bind(this, building)
        });
    },

    updateLevelText: function (parent, level) {
        parent.removeAllChildren();
        var styles = cleverapps.styles.HeroesScene;
        var levelText = cleverapps.UI.generateTTFText(level, styles.levelBlock.font);
        levelText.setPositionRound(parent.width / 2, parent.height / 2);
        parent.addChild(levelText);
    },

    updateAllInfo: function () {
        for (var color in this.heroBuildings) {
            this.updateBuildingInfo(this.heroBuildings[color]);
        }
    },

    updateBuildingInfo: function (building) {
        var buttonPos = building.button.getPosition();
        building.button.removeFromParent();
        building.button = this.createButton(building);
        building.addChild(building.button);
        building.button.setPositionRound(buttonPos);
    },

    heroBuildingUpgraded: function (color) {
        var styles = cleverapps.styles.HeroesScene;
        var building = this.heroBuildings[color];
        building.levelBlock.setVisible(false);
        building.button.setVisible(false);
        if (cleverapps.config.name === "adventure") {
            building.animation.setColor(new cc.Color(255, 255, 255, 255));
        } else {
            cleverapps.UI.convertToGrayScale(building.animation.spine, true);
        }
        this.updateBuildingInfo(building);
        building.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                building.levelBlock.setVisible(true);
                building.levelBlock.setScale(0);
                building.button.setScale(0);
                this.updateLevelText(building.levelBlock, match3.heroes.getLevel(color));
                building.levelBlock.runAction(new cc.ScaleTo(0.3, 1));
                building.button.runAction(new cc.ScaleTo(0.3, 1));
            }.bind(this)),
            new cc.DelayTime(0.5)
        ));

        var upgradeAnimation = new cleverapps.Spine(bundles.heroes_scene.jsons.hero_upgrade_json);
        upgradeAnimation.setAnimation(0, "animation", false);
        upgradeAnimation.setLocalZOrder(1);
        upgradeAnimation.setPositionRound(styles.upgradeAnimation);
        building.addChild(upgradeAnimation);

        cleverapps.audio.playSound(bundles.heroes_scene.urls.stage_change_effect);

        upgradeAnimation.setCompleteListener(function () {
            upgradeAnimation.runAction(new cc.RemoveSelf());
        });
    },

    displayHeroForce: function (color, force) {
        cleverapps.forces.create(this.heroBuildings[color].button, force, { actives: [this.heroBuildings[color]] });
    },

    customize: function () {
    },

    getBackgroundStyles: function () {
        return this.styling;
    },

    listBundles: function () {
        return ["heroes_scene"];
    }
});

cleverapps.styles.HeroesScene = {
    background: {
        bundle: "heroes_scene",
        backgroundId: "heroes_bg_vertical",
        scale: 2.0
    },

    margin: 40,

    upgradeAnimation: {
        x: { align: "center" },
        y: { align: "center" }
    },

    buttons: {
        width: 220,
        height: 70
    },

    levelBlock: {
        bg: bundles.heroes.frames.heroes_level_bg || bundles.main.frames.heroes_level_bg,
        font: cleverapps.styles.FONTS.WINDOW_WHITE_TEXT,
        width: 70,
        height: 50,
        x: -70,
        y: 135
    },

    house: {
        separateFromBg: false,
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    heroBuilding: {
        width: 120,
        height: 120,
        scale: 1.5
    },

    heroBuildings: [
        {
            color: "e",
            x: { align: "center", dx: -180 },
            y: { align: "center", dy: 320 }
        },
        {
            color: "d",
            x: { align: "center", dx: 180 },
            y: { align: "center", dy: 320 }
        },
        {
            color: "a",
            x: { align: "center", dx: -180 },
            y: { align: "center", dy: 0 }
        },
        {
            color: "b",
            x: { align: "center", dx: 180 },
            y: { align: "center", dy: 0 }
        },
        {
            color: "c",
            x: { align: "center", dx: -180 },
            y: { align: "center", dy: -320 }
        },
        {
            color: "w",
            x: { align: "center", dx: 180 },
            y: { align: "center", dy: -320 }
        }
    ]
};