/**
 * Created by vlad on 26.11.18.
 */

BurstCell.prototype.beforeBurstDuration = function () {
    return 0.7;
};

BurstCell.prototype.burstDuration = function (targetCell) {
    var dx = targetCell.x - this.x;
    var dy = targetCell.y - this.y;
    return this.beforeBurstDuration() + Math.sqrt(dx * dx + dy * dy) / BurstCell.BURST_SPEED;
};

BurstCell.prototype.smile = function () {
    if (this.alive) {
        this.animate(BaseCell.ANIMATION_SMILE);
    }
};