/**
 * Created by andrey on 24.08.17.
 */

var BombCell = function (x, y) {
    BaseCell.call(this, x, y);

    this.addComponent(ColorComponent);
    this.addComponent(SpecialColorComponent);

    this.untilExplode = undefined;
    this.basicUntilExplode = undefined;
    this.createType = undefined;
    this.putInPoolAvailable = true;
    this.onChangeUntilExplode = function () {};
    this.onBoom = function () {};

    Game.currentGame.counter.registerStage(400, BombCell.process);
};

BombCell.prototype = Object.create(BaseCell.prototype);
BombCell.prototype.constructor = BombCell;

BombCell.prototype.useCustomView = function () {
    return true;
};

BombCell.prototype.editorComponents = function () {
    return [BaseCellComponent, BombCellComponent, SwitchModeComponent];
};

BombCell.prototype.bundleId = function () {
    return "bomb";
};

BombCell.prototype.smile = function () {
    if (!this.alive) {
        return;
    }
    this.animate(BaseCell.ANIMATION_SMILE);
};

BombCell.prototype.explode = function (params) {
    if (this.createType !== CreateTypes.NOT_CREATE_AGAIN_TYPE) {
        var digits = "" + this.basicUntilExplode;
        Game.currentGame.field.addNextCell(BombCell.CODES.concat([CreateTypes.AUTO_CREATED_TYPE, "."]).concat(digits.split("")));
    }

    if (this.getGoalId() && Game.currentGame.goals.hasType(this.getGoalId())) {
        this.explodeViaCollect(params);
    } else {
        BaseCell.prototype._explode.call(this, params);
    }
};

BombCell.prototype.load = function (data) {
    data.shift();
    this.createType = data.shift();

    var color = data.shift();
    var colorComponent = this.findComponent(ColorComponent);
    colorComponent.setColor(color === "." ? this.getRandomColor() : color);

    var values = data.join("").split("_");
    if (values[1]) {
        this.untilExplode = parseInt(values[1]);
        this.basicUntilExplode = parseInt(values[0]);
    } else {
        this.untilExplode = parseInt(values[0]);
        this.basicUntilExplode = this.untilExplode;
    }
};

BombCell.prototype.decTimer = function () {
    this.untilExplode--;
    this.onChangeUntilExplode();
};

BombCell.prototype.addUntilExplode = function (amount) {
    this.untilExplode += amount;
    this.onChangeUntilExplode();
};

BombCell.prototype.save = function () {
    var digits = "" + this.basicUntilExplode;
    if (this.basicUntilExplode !== this.untilExplode) {
        digits += "_" + this.untilExplode;
    }
    return BombCell.CODES.concat([this.createType, this.findComponent(ColorComponent).color]).concat(digits.split(""));
};

BombCell.prototype.getViewClass = function () {
    return BombCellView;
};

BombCell.listToExplode = function () {
    var toExplode = [];

    if (!Game.currentGame || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return toExplode;
    }

    var targetTimer = Game.currentGame.bombCellCanProcess ? 1 : 0;

    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j]) {
                var bombCell = Game.currentGame.field.cells[i][j];
                if (bombCell.constructor === BombCell && bombCell.untilExplode === targetTimer) {
                    toExplode.push(bombCell);
                }
            }
        }
    }
    return toExplode;
};

BombCell.prepareToProcess = function () {
    if (!Game.currentGame) {
        return;
    }

    Game.currentGame.bombCellCanProcess = true;
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            var cell = Game.currentGame.field.cells[i][j];
            if (cell && cell instanceof BombCell) {
                cell.bombCellCanProcess = true;
            } else if (cell && cell.innerCell instanceof BombCell) {
                cell.innerCell.bombCellCanProcess = false;
            }
        }
    }
};

BombCell.checkExplode = function () {
    var toExplode = [];
    for (var i = 0; i < Field.SIZE; i++) {
        for (var j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j]) {
                var bombCell = Game.currentGame.field.cells[i][j];
                if (bombCell && bombCell instanceof BombCell) {
                    if (bombCell.createType === CreateTypes.CREATE_AGAIN_TYPE
                        && bombCell.untilExplode === 0) {
                        toExplode.push(bombCell);
                    }
                }
            }
        }
    }

    if (toExplode.length > 0) {
        BombCell.showAddBombMovesWindow(toExplode);
    }
};

BombCell.showAddBombMovesWindow = function (toExplode) {
    Game.currentGame.loseByBombExplodeRunning = true;

    var onSuccess = function () {
        Game.currentGame.loseByBombExplodeRunning = false;
        if (Game.currentGame.moves === 0) {
            Game.currentGame.incMoves();
        }

        Game.currentGame.counter.setTimeout(function () {
            for (var i = 0; i < Field.SIZE; i++) {
                for (var j = 0; j < Field.SIZE; j++) {
                    if (Game.currentGame.field.cells[i][j]) {
                        var bombCell = Game.currentGame.field.cells[i][j];
                        if (bombCell.constructor === BombCell) {
                            bombCell.addUntilExplode(AddBombMovesWindow.MOVES_TO_ADD);
                        }
                    }
                }
            }
        }, 100);
    };

    var onCancel = function () {
        toExplode.forEach(function (explodeBomb) {
            explodeBomb.onBoom();
        });
        var scene = cleverapps.scenes.getRunningScene();

        var animationNode = new cc.Node();

        var pos = scene.convertToNodeSpace(this.content.parent.convertToWorldSpace(this.content.getPosition()));
        animationNode.setPosition(pos);

        scene.addChild(animationNode);

        AnimationsLibrary.rays(this.bombIconAnimation);

        this.bombIconAnimation.replaceParentSamePlace(animationNode);
        this.timerTxt.replaceParentSamePlace(animationNode);
        this.sadIconAnimation.replaceParentSamePlace(animationNode);

        animationNode.setLocalZOrder(14);
        this.bombIconAnimation.setAnimation(0, "boom", false);
        this.sadIconAnimation.setAnimation(0, "windowsboom", false);

        var splash = new cleverapps.Spine(bundles.bomb.jsons.bomb_splash_json);
        if (cleverapps.styles.BombCellView.skins) {
            splash.setSkin("skin_" + toExplode[0].findComponent(ColorComponent).color);
        }
        var sceneSize = cleverapps.resolution.getSceneSize();
        splash.setPosition(sceneSize.width / 2, sceneSize.height / 2);
        splash.setLocalZOrder(15);
        scene.addChild(splash);
        splash.visible = false;

        Game.currentGame.counter.setTimeout(function () {
            splash.setAnimation(0, "animation", false);
        }, 1000);

        Game.currentGame.counter.setTimeout(function () {
            Game.currentGame.lose();
        }, 3000);
    };

    cleverapps.audio.playSound(bundles.game.urls.no_moves_effect);

    var id = Game.currentGame.addBombMovesId || 0;
    var price = Prolongation.PRICES[id];

    if (cleverapps.forces.isShown(Forces.FREE_BOMB_FORCE.id)) {
        Game.currentGame.addBombMovesId = Math.min(id + 1, Prolongation.PRICES.length - 1);
    } else {
        price = 0;
    }

    var game = Game.currentGame;

    cleverapps.focusManager.displayWhenFreeFocus({
        focus: "AddBombMovesWindow",
        control: "panel_info",
        action: function (f) {
            if (!game || Game.currentGame !== game || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN) {
                f();
                return;
            }

            cleverapps.focusManager.compound(f, [
                function (f) {
                    Game.currentGame.counter.setTimeout(f, 500);
                },
                function (f) {
                    new AddBombMovesWindow(price, toExplode[0].findComponent(ColorComponent).color, onSuccess, onCancel);
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            ]);
        }
    });
};

BombCell.process = function () {
    if (!Game.currentGame || Game.currentGame.goalCounter.isActive() || !Game.currentGame.bombCellCanProcess || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }
    Game.currentGame.bombCellCanProcess = false;

    var toExplode = [], i, j;
    for (i = 0; i < Field.SIZE; i++) {
        for (j = 0; j < Field.SIZE; j++) {
            if (Game.currentGame.field.cells[i][j]) {
                var bombCell = Game.currentGame.field.cells[i][j];
                if (bombCell.constructor === BombCell && bombCell.bombCellCanProcess) {
                    bombCell.bombCellCanProcess = false;
                    if (bombCell.createType === CreateTypes.AUTO_CREATED_TYPE) {
                        bombCell.createType = CreateTypes.CREATE_AGAIN_TYPE;
                    } else {
                        bombCell.decTimer();
                        if (bombCell.untilExplode === 0) {
                            toExplode.push(bombCell);
                        }
                    }
                }
            }
        }
    }

    if (toExplode.length > 0) {
        BombCell.showAddBombMovesWindow(toExplode);
    }
};

BombCell.prototype.getGoalId = function () {
    return BombCell.GOAL_ID;
};

BombCell.GOAL_ID = "bomb";