/**
 * Created by mac on 7/24/17.
 */

var GoalView = cc.Node.extend({
    getStyles: function () {
        var styles = cleverapps.styles.BaseGoalView;

        if ([CoinCell.GOAL_ID].indexOf(this.goal.type) !== -1) {
            styles = cleverapps.overrideStyles(styles, {
                scaleX: Math.abs(styles.scaleX)
            }, true);
            styles.no_moves = cleverapps.overrideStyles(styles.no_moves, {
                scaleX: Math.abs(styles.no_moves.scaleX)
            }, true);
            styles.withTournament = cleverapps.overrideStyles(styles.withTournament, {
                scaleX: Math.abs(styles.withTournament.scaleX)
            }, true);
        }

        if (Game.currentGame.hasDanger()) {
            return styles.danger;
        }
        if (Game.currentGame && Game.currentGame.moves <= 0 && styles.no_moves && Game.currentGame.levelContent.moves > 0) {
            return styles.no_moves;
        }
        if (Game.currentGame.secondaryMission) {
            return styles.withTournament;
        }

        return styles;
    },

    getTarget: function () {
        return this.icon;
    },

    ctor: function (goal, icon) {
        this._super();

        this.goal = goal;
        if (icon.cell) {
            this.cell = icon.cell;
        }

        this.amountType = this.getAmountType();

        this.icon = icon;
        this.icon.setLocalZOrder(0);

        var styles = this.getStyles();

        this.setContentSize(styles.width, styles.height);
        this.setAnchorPoint(0.5, 0.5);

        this.baseIconScaleX = styles.scaleX;
        this.baseIconScaleY = styles.scaleY;

        this.icon.setScale(this.baseIconScaleX, this.baseIconScaleY);

        this.icon.setPositionRound(this.width / 2 + styles.icon.x, this.height - cleverapps.styles.BaseCellView.CELL_SIZE_PX * this.icon.scaleY / 2 + styles.icon.y);
        this.addChild(this.icon);

        if (!(goal instanceof Goal)) {
            return;
        }

        this.collected = goal.getAmount();

        this.amount = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.GOAL_TEXT);
        this.amount.setPositionRound(this.width / 2 + styles.amount.x, styles.amount.y);
        this.amount.setLocalZOrder(1);
        this.addChild(this.amount);

        if (Game.currentGame.hasDanger()) {
            var progressBar = this.progressBar = this.createProgressBar();
            var goalBg = this.createGoalBg();

            this.addChild(progressBar, -1);
            this.addChild(goalBg, -1);

            this.icon.setPosition(goalBg.getPosition());

            this.amount.setPositionRound(styles.progressBar.text);
            this.amount.setFont(cleverapps.styles.FONTS.DANGER_GOAL);
        }

        var checkMark = new cc.Sprite(bundles.checkbox.frames.check_mark_png);
        var checkMarkPos = this.amount.getPosition();
        var delta = styles.checkMarkOffset || cc.p(0, 0);
        checkMark.setPositionRound(checkMarkPos.x + delta.x, checkMarkPos.y + delta.y);
        checkMark.setVisible(false);
        this.checkMark = checkMark;
        this.addChild(checkMark);

        this.update();

        goal.on("update", this.createListener(this.update.bind(this)));
        goal.on("beforeBonus", this.createListener(this.beforeAnimateBonus.bind(this)));
        goal.on("bonus", this.createListener(this.animateBonus.bind(this)));
        goal.on("onCollect", this.createListener(this.onCollect.bind(this)));
        goal.on("collect", this.createListener(this.animateCollect.bind(this)));
    },

    update: function (delta) {
        delta = delta || 0;
        this.collected += delta;
        var amount;

        if (this.amountType === GoalView.DISPLAYED_AMOUNT.TOTAL) {
            amount = this.collected;
        } else if (this.amountType === GoalView.DISPLAYED_AMOUNT.REMAINING) {
            amount = this.goal.getTarget() - this.collected;
        } else if (this.amountType === GoalView.DISPLAYED_AMOUNT.FRACTION) {
            amount = this.collected + "/" + this.goal.getTarget();
        }

        this.amount.setString(amount);

        if (this.progressBar) {
            this.progressBar.setPercentage(this.collected);
        }

        if (cleverapps.config.editorMode) {
            return;
        }

        if (this.collected >= this.goal.getTarget() && this.goal.withTarget()) {
            this.setCompleted();
        } else {
            this.setIncompleted();
        }
    },

    createProgressBar: function () {
        var styles = this.getStyles();

        var progressBar = new ScaledProgressBar({
            progress: bundles.danger_component.frames.progress,
            background: bundles.danger_component.frames.progress_bg
        });

        progressBar.setLength(styles.progressBar.width);
        progressBar.setGoal(this.goal.getTarget());
        progressBar.setPercentage(this.collected);

        progressBar.setPositionRound(styles.progressBar);

        return progressBar;
    },

    createGoalBg: function () {
        var styles = this.getStyles();

        var goalBg = this.goalBg = new cc.Sprite(bundles.danger_component.frames.goal_bg);
        goalBg.setPositionRound(styles.goalBg);

        return goalBg;
    },

    setCompleted: function () {
        this.amount.setVisible(false);
        this.checkMark.setVisible(!this.uppedForStartAnimation);

        if (cleverapps.config.editorMode) {
            this.amount.setVisible(true);
            var delta = cleverapps.styles.BaseGoalView.editorModeCheckOffset;
            this.checkMark.setPosition(this.checkMark.x + delta.x, this.checkMark.y + delta.y);
        }

        cleverapps.audio.playSound(bundles.game.urls.goal_completed_effect);
    },

    setIncompleted: function () {
        this.amount.setVisible(!this.uppedForStartAnimation);
        this.checkMark.setVisible(false);
    },

    setFailed: function () {
        if (this.amount) {
            this.amount.setColor(cleverapps.styles.FONTS.ADDMOVES_GOAL_RED_TEXT.color);
        }
    },

    animateScale: function () {
        this.icon.stopAllActions();
        this.icon.setScale(this.baseIconScaleX * 1.2, this.baseIconScaleY * 1.2);
        this.icon.runAction(new cc.ScaleTo(0.2, this.baseIconScaleX, this.baseIconScaleY));
    },

    animateSmile: function () {
        if (!this.cell) {
            return;
        }

        var goalCoefComponent = this.cell.findComponent && this.cell.findComponent(GoalCoefComponent);
        if (this.cell.smile) {
            this.cell.smile();
        } else if (goalCoefComponent && goalCoefComponent.smile) {
            goalCoefComponent.smile();
        }
    },

    completeAnimationOnResize: function () {
        if (this.showUpAction) {
            this.stopAction(this.showUpAction);
        }
    },

    upForStartAnimation: function () {
        this.originalScale = this.scale;
        this.originalRotation = this.rotation;
        this.originalPosition = this.getPosition();
        this.originalParent = this.parent;

        this.scale = cleverapps.styles.GoalsView.startAnimation.goals.scale[cleverapps.resolution.mode];
        if (Game.currentGame.secondaryMission) {
            this.scale *= Math.abs(cleverapps.styles.BaseGoalView.scaleY) / Math.abs(cleverapps.styles.BaseGoalView.withTournament.scaleY);
        }

        if (Game.currentGame.hasDanger()) {
            this.progressBar.visible = false;
            this.goalBg.visible = false;

            this.icon.setPosition(this.width / 2, this.height / 2);
        }
        if (this.life) {
            this.life.visible = false;
        }
        if (this.amount) {
            this.amount.visible = false;
        }

        this.rotation = 0;

        this.uppedForStartAnimation = true;
        this.removeFromParent(false);

        this.setVisible(true);
    },

    downFromStartAnimation: function () {
        this.replaceParent(this.originalParent);

        if (this.originalScale) {
            this.scale = this.originalScale;
            this.rotation = this.originalRotation;
            this.setPosition(this.originalPosition);
        }

        if (Game.currentGame.hasDanger()) {
            this.progressBar.visible = true;
            this.goalBg.visible = true;

            this.icon.setPosition(this.goalBg.getPosition());
        }

        if (this.life) {
            this.life.visible = true;
        }
        if (this.amount) {
            if (!this.goal.isCompleted()) {
                this.amount.visible = true;
            }
        }
        delete this.uppedForStartAnimation;
    },

    runStartAnimation: function (f, points, targetScale) {
        this.showUpAction = new cc.Sequence(
            new cc.Spawn(
                new cc.PlaySound(bundles.game.urls.goals_fly_effect),
                new cc.BezierTo(0.7, points).easing(cc.easeIn(1)),
                new cc.ScaleTo(0.7, targetScale.x, targetScale.y)
            ),
            new cc.Sequence(
                new cc.CallFunc(function () {
                    if (this.type === CoinCell.GOAL_ID) {
                        cleverapps.audio.playSound(bundles.game.urls.coins_bonus_effect);
                    }
                }),
                new cc.ScaleTo(0.1, targetScale.x + 0.15, targetScale.y - 0.15),
                new cc.ScaleTo(0.1, targetScale.x, targetScale.y)
            )
        ).setFinalize(function () {
            if (this.actor) {
                this.actor.animate(Actor.ANIMATIONS.IDLE);
            }
            if (this.life) {
                this.life.baseScale = this.life.getScale();

                this.life.setVisible(true);
                this.life.setScale(this.life.baseScale);
            }
            this.animateSmile();
            f();

            delete this.showUpAction;
        }.bind(this));

        this.runAction(this.showUpAction);
    },

    getTargetPosition: function (object) {
        if (this.cell && this.cell.getGoalTargetPosition) {
            return this.cell.getGoalTargetPosition(object.getRelativeCoordinates(this.getTarget()));
        }

        var styles = cleverapps.styles.BaseGoalView.collect.bySpine;
        if (styles) {
            var offset = styles[this.actor.currSpineId] || styles.default;

            return object.getParent().convertToNodeSpace(this.actor.getParent().convertToWorldSpace(cc.p(this.actor.x + offset.x, this.actor.y + offset.y)));
        }
    },

    goalCollectSound: function () {
        var soundRes;
        var soundLevels = [];

        for (var i = 0; i < 3; i++) {
            if (bundles.game.urls["goal_inc_effect_" + i]) {
                soundLevels.push(i);
            }
        }

        var soundLevel = cleverapps.Random.mathChoose(soundLevels);
        if (soundLevel) {
            soundRes = bundles.game.urls["goal_inc_effect_" + soundLevel];
        } else {
            soundRes = bundles.game.urls.goal_inc_effect;
        }

        cleverapps.audio.playSound(soundRes, {
            throttle: 100
        });

        if (this.goal.isBonusElement()) {
            cleverapps.audio.playSound(bundles.game.urls.coins_bonus_effect, {
                throttle: 100
            });
        }
    },

    animateCollect: function (delta, flyIcon, options) {
        options = options || {};
        this.onCollectFinished = function () {};
        flyIcon.runAction(new cc.Sequence(
            AnimationsLibrary.animateCollect(flyIcon, this.icon, {
                adjustToTarget: true
            }),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                if (this.onCollectFinished) {
                    this.onCollectFinished();
                    delete this.onCollectFinished;
                }
                options.callback && options.callback();
                this.onCollect(delta);
            }.bind(this))

        ));
    },

    onCollect: function (delta) {
        this.update(delta);
        this.goalCollectSound();
        this.animateScale();
        this.animateSmile();
    },

    highlight: function () {
        cleverapps.audio.playSound(bundles.game.urls.goal_highlight_sfx);

        var rays = new cleverapps.Spine(bundles.main.jsons.reward_spine_json);
        this.addChild(rays, -2);
        rays.setPositionRound(this.width / 2, this.height / 2);
        rays.setAnimation(0, "animation", true);

        var pulseTime = 0.5;

        rays.setScale(0.15);
        rays.runAction(new cc.Sequence(
            new cc.ScaleTo(pulseTime / 2, 0.42),
            new cc.ScaleTo(pulseTime / 2, 0.15),
            new cc.RemoveSelf()
        ));

        this.icon.runAction(new cc.Sequence(
            new cc.ScaleTo(pulseTime / 2, this.baseIconScaleX * 1.3, this.baseIconScaleY * 1.3),
            new cc.ScaleTo(pulseTime / 2, this.baseIconScaleX, this.baseIconScaleY)
        ));
    },

    beforeAnimateBonus: function () {
        if (this.checkMark) {
            this.checkMark.setVisible(false);
        }
        if (this.amount) {
            this.amount.setVisible(false);
        }
        if (this.life) {
            this.life.setVisible(false);
        }
    },

    animateBonus: function (duration, oneCoinCallback, order) {
        var cell = this.cell;
        var ViewClass = cell.getViewClass();
        var icon = new ViewClass(cell);

        var chest = cleverapps.scenes.getRunningScene().bonusChest;

        var scaleX = this.baseIconScaleX || cleverapps.styles.BaseGoalView.icon.scale;
        var scaleY = this.baseIconScaleY || cleverapps.styles.BaseGoalView.icon.scale;
        if (this.actor) {
            icon.setScale(0);
            icon.setPosition(cleverapps.styles.BaseGoalView.bonus);
        } else {
            icon.setScale(scaleX, scaleY);
            icon.setPosition(this.icon.getPosition());
        }

        icon.animation.setAnimation(0, "fly", false);
        this.addChild(icon, this.goal.amount - order);

        var flyTarget = new cc.Node();
        flyTarget.setAnchorPoint(0.5, 0.5);
        flyTarget.setPositionRound(chest.getFlyTargetPosition());
        chest.animation.addChild(flyTarget);

        icon.runAction(new cc.Sequence(
            AnimationsLibrary.animateCollect(icon, flyTarget, {
                duration: duration / 1000,
                randomPath: true
            }),
            new cc.CallFunc(function () {
                oneCoinCallback();
                chest.addGold();
            }),
            new cc.RemoveSelf()
        ));
    },

    getAmountType: function () {
        if (Game.currentGame.hasDanger() || cleverapps.config.editorMode) {
            return GoalView.DISPLAYED_AMOUNT.FRACTION;
        }
        if (this.goal.isBonusElement() || !this.goal.withTarget()) {
            return GoalView.DISPLAYED_AMOUNT.TOTAL;
        }

        return GoalView.DISPLAYED_AMOUNT.REMAINING;
    }
});

GoalView.FLY_SIGN = 1;

cleverapps.styles.BaseGoalView = {
    width: 100,
    height: 80,
    scaleX: 0.9,
    scaleY: 0.9,

    icon: {
        x: 0,
        y: 12,
        margin: {
            x: -15,
            y: 15
        }
    },

    amount: {
        x: 25,
        y: 10,
        scale: 0.8
    },

    editorModeCheckOffset: {
        x: 40,
        y: 40
    },

    bonus: {
        x: 70,
        y: 100
    },

    withTournament: {
        width: 60,
        height: 60,
        scaleX: 0.8,
        scaleY: 0.8,

        icon: {
            x: 0,
            y: 20
        },

        amount: {
            x: 25,
            y: 5,
            scale: 0.8
        }
    },

    no_moves: {
        width: 100,
        height: 80,
        scaleX: 1,
        scaleY: 1,

        icon: {
            x: 0,
            y: 12
        },

        amount: {
            x: 40,
            y: -30,
            scale: 1
        }
    },

    danger: {
        x: { align: "center" },
        y: { align: "center" },

        width: 375,
        height: 130,
        scaleX: 0.75,
        scaleY: 0.75,

        icon: {
            x: 0,
            y: 0
        },

        amount: {
            x: 0,
            y: 0,
            scale: 1
        },

        progressBar: {
            width: 310,

            x: { align: "center", dx: -20 },
            y: { align: "center", dy: -3 },

            text: {
                x: { align: "center", dx: -30 },
                y: { align: "center", dy: -1 }
            }
        },

        goalBg: {
            x: { align: "right" },
            y: { align: "center" }
        },

        checkMarkOffset: {
            x: 0,
            y: -7
        }
    }
};

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    DANGER_GOAL: {
        size: 45,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

GoalView.DISPLAYED_AMOUNT = {};
GoalView.DISPLAYED_AMOUNT.TOTAL = 0;
GoalView.DISPLAYED_AMOUNT.REMAINING = 1;
GoalView.DISPLAYED_AMOUNT.FRACTION = 2;
