/**
 * Created by slava on 20.09.18.
 */

IceTileView.prototype.onCollectAnimation = function (object, id, groupSize) {
    if (Game.currentGame) {
        var icon = new cc.Sprite(bundles[this.tile.bundleId()].frames.ice_goal);
        icon.setLocalZOrder(5);
        var pos = this.getPosition();
        icon.setPosition(pos.x, pos.y);
        icon.setScale(0.1);
        icon.setVisible(false);
        Game.currentGame.field.onGetView().addChild(icon);
        icon.runAction(new cc.Sequence(
            new cc.DelayTime(0.30),
            new cc.CallFunc(function () {
                icon.setVisible(true);
            }),
            new cc.ScaleTo(0.4, 1.0),
            new cc.CallFunc(function () {
                if (Game.currentGame) {
                    var goal = Game.currentGame.goals.findTargetFor(object.getGoalId());
                    goal.collect(1, icon);
                }
            })
        ));
    }
};

IceTile.prototype.getExplodeInterval = function () {
    return 0.6;
};