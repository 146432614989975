/**
 * Created by vladislav on 1/16/19
 */

TruffleCell.prototype.hurt = function (explodeParams) {
    if (this.lives < 1 || !this.alive) {
        return;
    }

    this.lives--;

    this.animate(BaseCell.ANIMATION_HURT);
    if (this.lives > 0) {
        var colorComponent = this.findComponent(ColorComponent);
        colorComponent.setColor(this.colors[this.colors.length - this.lives]);
    }
    var lives = this.lives;
    Game.currentGame.counter.setTimeout(function () {
        this.onChangeLivesListener();
        if (lives < 1) {
            this.explode(explodeParams);
        }
    }.bind(this), this.hurtDuration() * 1000);

    Game.currentGame.counter.setTimeout(function () {}, 600);
};

TruffleCell.prototype.hurtDuration = function () {
    return this.lives < 1 ? 0.55 : 0.2;
};