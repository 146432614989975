/**
 * Created by ivan on 06.03.18.
 */

BombByLeftMovesView.prototype.addImage = function () {};

BombByLeftMovesView.prototype.animate = function () {
    if (!this.animation) {
        return;
    }

    var shift = cleverapps.styles.BombByLeftMovesView.shift[cleverapps.resolution.mode];
    
    var start;
    if (this.bomb.x === -1) {
        start = BaseCellView.alignInTheGrid(-1, this.bomb.y);
        start.x += shift;
        this.rotation = 90;

        if (this.bomb.reverse) {
            start = BaseCellView.alignInTheGrid(Field.SIZE, this.bomb.y);
            start.x -= shift;
            this.rotation = 270;
        }
    } else {
        start = BaseCellView.alignInTheGrid(this.bomb.x, -1);
        start.y -= shift;
        this.rotation = 180;

        if (this.bomb.reverse) {
            start = BaseCellView.alignInTheGrid(this.bomb.x, Field.SIZE);
            start.y += shift;
            this.rotation = 0;
        }
    }
    
    this.setPositionRound(start);

    this.animation.animate();
    this.setScale(1);
    this.runAction(new cc.Sequence(
        new cc.DelayTime(1),
        new cc.CallFunc(function () {
            cleverapps.audio.playSound(bundles.game.urls.rockets_effect);
        })
    ));
};

cleverapps.overrideStyles(cleverapps.styles.BombByLeftMovesView, {
    shift: {
        0: 700,
        1: 600,
        2: 300
    },
    parentScene: false
});

BombByLeftMovesView.SPEED = 10.0;
BombByLeftMovesView.DELAY = 1000;
BombByLeftMovesAnimation.POOL_SIZE = 10;