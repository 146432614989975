/**
 * Created by mac on 2019-10-14
 */

var BuildingInfoWindow = CleverappsWindow.extend({
    onWindowLoaded: function (building, name) {
        this.building = building;

        this._super({
            title: Messages.get("building." + building.name),
            name: name || "buildinginfowindow",
            content: this.generateContent(),
            styles: cleverapps.styles.BuildingInfoWindow.Window,
            buttons: this.getButton(),
            button: this.getButton()
        });
    },

    getButton: function () {
        var canChoose = this.building.choices && this.building.isComplete();
        return {
            text: Messages.get(!canChoose ? "OK" : "BuildingInfoWindow.redoButton"),
            width: cleverapps.styles.BuildingInfoWindow.button.width,
            onPressed: this.onPressed.bind(this)
        };
    },

    onPressed: function () {
        this.close();

        if (this.building.choices && this.building.isComplete()) {
            cleverapps.focusManager.distract({
                focus: "ChoiceWindowRedo",
                actions: [
                    function (f) {
                        new MakeChoiceWindow(this.building, true);
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }.bind(this)
                ]
            });
        }
    },

    getInfo: function () {
        var text = "BuildingInfoWindow.text";
        if (Messages.has("building." + this.building.name + ".info")) {
            text = "building." + this.building.name + ".info";
        }
        return cleverapps.UI.generateTTFText(text, cleverapps.styles.FONTS.WINDOW_TEXT);
    },

    generateContent: function () {
        var styles = cleverapps.styles.BuildingInfoWindow;

        var bg;
        if (styles.bg.img) {
            bg = cleverapps.UI.createScale9Sprite(styles.bg.img, cleverapps.UI.Scale9Rect.TwoPixelXY);
        } else {
            bg = new cc.Node();
            bg.setAnchorPoint(0.5, 0.5);
        }
        bg.setContentSize2(styles.bg);

        var animationNode = new cc.Node();
        animationNode.setAnchorPoint(0.5, 0.5);
        animationNode.setContentSize2(styles.animation);
        animationNode.setPosition(bg.width / 2, bg.height / 2);
        bg.addChild(animationNode);

        var animation = BuildingView.createView(this.building, {
            icon: true,
            size: {
                width: animationNode.width,
                height: animationNode.height
            }
        });
        animation.setPosition(animationNode.width / 2, animationNode.height / 2);
        animationNode.addChild(animation);

        var text = this.getInfo();

        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.textWidth, 0);
        text.fitTo(undefined, text.height);

        return new cleverapps.Layout([bg, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    }
});

cleverapps.styles.BuildingInfoWindow = {
    textWidth: 580,
    margin: 20,
    bg: {
        img: false,
        width: 650,
        height: 380
    },

    animation: {
        width: 400,
        height: 240
    },

    button: {
        width: 300
    },

    Window: {}
};