/**
 * Created by slava on 02.02.17.
 */

var BaseCell = function (x, y, options) {
    this.x = x;
    this.y = y;
    this.movable = false;
    this.hurtable = true;
    this.lives = 1;
    this.alive = true;
    this.explodeOnGameFinish = false;

    this.isHovered = false;

    this.didFall = false;
    this.willFall = false;
    
    this.depth = 0;
    this.onDepthChanged = function () {};
    this.onGetView = function () {};
    
    this.visible = true;
    this.onVisibleChanged = function () {};

    if (x < 0) {
        this.isForGoal = true;
    }

    this.onDebugListener = function () {};

    this.onAnimationListener = function () {};

    this.onHoverListener = function () {};

    this.onStartMoveListener = function () {};
    this.onFinishMoveListener = function () {};
    this.onWrongMoveListener = function () {};
    this.onBeforeExplodeListener = function () {};
    this.onAfterExplodeListener = function () {};
    this.onChangeLivesListener = function () {};
    this.onDeleteListener = function () {};
    this.onAppear = function () {};
    this.onFinishAppear = function () {};
    this.onAvailableMoveListener = function () {};
    this.onGameFinishListener = function () {};

    this.components = [];

    if (options && options.components) {
        options.components.forEach(function (Component) {
            this.addComponent(Component, options);
        }, this);
    }
};

BaseCell.prototype.addComponent = function (Component, options) {
    if (!this.findComponent(Component)) {
        var component = CellsComponentsFactory(this, Component, options || {});
        if (component) {
            this.components.push(component);
        }
        return component;
    }
};

BaseCell.prototype.removeComponent = function (Component) {
    for (var i = 0; i < this.components.length; i++) {
        if (this.components[i] instanceof Component) {
            this.components.splice(i, 1);
            break;
        }
    }
};

BaseCell.prototype.findComponent = function (Component) {
    var searchList = Array.isArray(Component) ? Component : [Component];

    for (var i = 0; i < searchList.length; i++) {
        for (var j = 0; j < this.components.length; j++) {
            if (this.components[j] instanceof searchList[i]) {
                return this.components[j];
            }
        }
    }
};

BaseCell.prototype.setInnerCell = function (innerCell) {
    if (this.innerCell) {
        this.innerCell.parentCell = undefined;
        this.innerCell.setDepth(0);
    }
    this.innerCell = innerCell;
    if (innerCell) {
        innerCell.parentCell = this;
        innerCell.setDepth(this.depth + 1);
        innerCell.changeVisible(this.visible && !(this.hideInnerCell));
    }
};

BaseCell.prototype.setDepth = function (depthValue) {
    this.depth = depthValue;
    this.onDepthChanged();
    if (this.innerCell) {
        this.innerCell.setDepth(this.depth + 1);
    }

    var eaterComponent = this.findComponent(EaterComponent);
    if (eaterComponent) {
        eaterComponent.setDepth(depthValue);
    }
};

BaseCell.prototype.onGameFinish = function () {
    this.onGameFinishListener();

    if (this.explodeOnGameFinish) {
        this.hurt();
    }
};

BaseCell.prototype.changeVisible = function (newVisibleFlagValue) { // newVisibleFlagValue = false / true
    if (this.innerCell) {
        this.innerCell.changeVisible(newVisibleFlagValue && !(this.hideInnerCell));
    }
    this.visible = newVisibleFlagValue;
    this.onVisibleChanged(newVisibleFlagValue);
};

BaseCell.prototype.getLives = function () {
    var component = this.findComponent([SpecialShapeComponent, BigComponent]);
    if (component) {
        return component.getLives();
    }

    return this.lives;
};

BaseCell.prototype.onBeforeHurtViaBooster = function () {};

BaseCell.prototype.editorComponents = function () {
    var component = this.findComponent([EaterComponent, SpecialShapeComponent, BigComponent]);
    if (component) {
        return component.editorComponents();
    }

    return [BaseCellComponent];
};

BaseCell.prototype.canRepaint = function (inShuffle) {
    var component = this.findComponent([DecoratorComponent, SpecialColorComponent, ColorComponent]);
    if (component) {
        return component.canRepaint(inShuffle);
    }

    return false;
};

BaseCell.prototype.canDelete = function () {
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent && markComponent.mark) {
        return false;
    }

    var component = this.findComponent([GoalCoefComponent, ColorComponent]);
    if (component) {
        return component.canDelete();
    }

    return false;
};

BaseCell.prototype.repaint = function () {
    var component = this.findComponent([DecoratorComponent, SpecialColorComponent, ColorComponent]);
    if (component) {
        var cell = component.repaint();

        var markComponent = cell.findComponent(MarkComponent);
        if (markComponent) {
            cell = markComponent.repaint(this);
        }

        return cell;
    }

    return this;
};

BaseCell.prototype.totalLives = function () {
    var tile;
    if (Game.currentGame) {
        tile = Game.currentGame.field.floor[this.y][this.x];
    }
    return this.getLives() + (this.innerCell ? this.innerCell.getLives() : 0) + (tile && tile.lives ? tile.lives : 0);
};

BaseCell.prototype.hover = function (state) {
    var component = this.findComponent([SpecialShapeComponent, DecoratorComponent]);
    if (component && !component.hover(state)) {
        return;
    }

    if (this.isHovered === state) {
        return;
    }

    this.isHovered = state;
    this.onHoverListener(state);
};

BaseCell.prototype.animate = function (type, params) {
    var component = this.findComponent([SpecialShapeComponent, BigComponent]);
    if (component && !component.animate(type, params)) {
        return;
    }

    this.onAnimationListener(type, params);

    var decoratorComponent = this.findComponent(DecoratorComponent);
    if (decoratorComponent) {
        decoratorComponent.animate(type, params);
    }
};

BaseCell.prototype.load = function (data, loader) {
    var component = this.findComponent([SpecialShapeComponent, MuffinComponent, BigComponent, DecoratorComponent, GoalCoefComponent, ColorComponent]);
    if (component) {
        component.load(data, loader);
        return;
    }

    throw "Not implemented";
};

BaseCell.prototype.save = function () {
    var component = this.findComponent([SpecialShapeComponent, MuffinComponent, BigComponent, GoalCoefComponent, ColorComponent]);
    if (component) {
        return component.save();
    }

    throw "Not implemented";
};

BaseCell.prototype.getPosition = function () {
    return {
        y: this.y,
        x: this.x
    };
};

BaseCell.prototype.willEndFall = function () {
    if (this.didFall) {
        this.didFall = false;

        this.animate(BaseCell.ANIMATION_DOWN);
    }
};

BaseCell.prototype.onBurnStage = function () {
    this.willBurn = false;
};

BaseCell.prototype.move = function (destination, options, onFinishCallback) {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    if (decoratorComponent) {
        if (onFinishCallback) {
            onFinishCallback = cleverapps.wait(2, onFinishCallback);
        }
        decoratorComponent.move(destination, options, onFinishCallback);
    }

    options = options || {};
    options.moveInterval = options.moveInterval || 0.1 * (Math.abs(destination.y - this.y) + Math.abs(destination.x - this.x));

    Game.currentGame.counter.playActions([
        Game.currentGame.counter.createDelayAction(options.delay * 1000 || 0),

        function (callback) {
            this.onStartMoveListener(destination, options);
            callback();
        }.bind(this),

        Game.currentGame.counter.createDelayAction(options.moveInterval * 1000),

        function (callback) {
            this.onFinishMoveListener(destination, options);

            if (onFinishCallback) {
                onFinishCallback();
            }

            if (options.squeeze) {
                if (destination.y < this.y) {
                    this.animate(BaseCell.ANIMATION_DOWN);
                } else if (destination.y > this.y) {
                    this.animate(BaseCell.ANIMATION_UP);
                } else if (destination.x > this.x) {
                    this.animate(BaseCell.ANIMATION_RIGHT);
                } else {
                    this.animate(BaseCell.ANIMATION_LEFT);
                }
            }

            this.x = destination.x;
            this.y = destination.y;

            callback();
        }.bind(this),

        function (callback) {
            if (decoratorComponent) {
                decoratorComponent.afterMove(destination, options);
            }

            callback();
        }
    ]);
};

BaseCell.prototype.hurt = function (explodeParams) {
    var component = this.findComponent([SpecialShapeComponent, BigComponent, DecoratorComponent]);
    if (component && !component.hurt(explodeParams)) {
        return;
    }

    if (this.lives < 1 || !this.alive || !this.hurtable) {
        return;
    }

    this.lives--;
    this.onChangeLivesListener();
    this.animate(BaseCell.ANIMATION_HURT);

    Game.currentGame.counter.setTimeout(function () {
        if (this.lives <= 0) {
            if (cleverapps.gameModes.silentGoals) {
                BaseCell.prototype._explode.call(this, explodeParams);
                return;
            }

            this.explode(explodeParams);
        }
    }.bind(this), this.hurtDuration() * 1000);
};

BaseCell.prototype.hurtDuration = function () {
    var eaterComponent = this.findComponent(EaterComponent);
    if (eaterComponent) {
        return eaterComponent.hurtDuration();
    }

    return 0.0;
};

BaseCell.prototype.getColor = function () {
    var colorComponent = this.findComponent(ColorComponent);
    if (colorComponent) {
        return colorComponent.color;
    }

    return undefined;
};

BaseCell.prototype.boom = function (coef) {
    var component = this.findComponent([MuffinComponent, GoalCoefComponent]);
    if (component) {
        component.boom(coef);
        return;
    }

    if (!this.alive || this.lives < 1) {
        return;
    }
    this.animate(BaseCell.ANIMATION_BOOM_NEIGHBOUR);
};

BaseCell.prototype.bundleId = function () {
    var muffinComponent = this.findComponent(MuffinComponent);
    if (muffinComponent) {
        return muffinComponent.bundleId();
    }

    return false;
};

BaseCell.prototype.getTypeId = function () {
    var id = 1;
    if (!Game.currentGame) {
        return id;
    }

    for (var i in Game.currentGame.loader.map) {
        if (this.constructor === Game.currentGame.loader.map[i]) {
            break;
        }
        id++;
    }
    id *= 10;
    var color = this.getColor();
    if (color) {
        id++;
        for (i = 0; i < ColorComponent.CODES.length; i++) {
            if (color === ColorComponent.CODES[i]) {
                break;
            }
            id++;
        }
    }
    return id;
};

BaseCell.prototype.canMoveWith = function () {
    return false;
};

BaseCell.prototype.delete = function () {
    if (Game.currentGame && Game.currentGame.field.isSelected(this)) {
        Game.currentGame.field.removeSelection();
    }
    this.onDeleteListener();
    this.viewExists = undefined;
};

BaseCell.prototype.explodeDuration = function () {
    return Match3Rules.ExplodeDuration;
};

BaseCell.prototype.getMinLives = function () {
    return 1;
};

BaseCell.prototype.onExplode = function () {
    var decoratorComponent = this.findComponent(DecoratorComponent);
    if (decoratorComponent) {
        decoratorComponent.onExplode();
        return;
    }

    if (Game.currentGame && Game.currentGame.field.floor[this.y][this.x]) {
        Game.currentGame.field.floor[this.y][this.x].hurt();
    }
};

BaseCell.neighbors = [{ x: 0, y: 1 }, { x: 0, y: -1 }, { x: 1, y: 0 }, { x: -1, y: 0 }];

BaseCell.prototype.forNeighbor = function (iterator) {
    if (!Game.currentGame) {
        return;
    }
    var field = Game.currentGame.field;
    BaseCell.neighbors.forEach(function (dir) {
        if (field.isCellInField(this.y + dir.y, this.x + dir.x)) {
            iterator(field.cells[this.y + dir.y][this.x + dir.x]);
        }
    }, this);
};

BaseCell.prototype.iterateLineOfFire = function (x, y, dir, iterator, getCellsOnLineOfFire) {
    if (!Game.currentGame) {
        return;
    }

    var field = Game.currentGame.field;
    while (field.isWithinBounds(y, x) && !(field.cells[y][x] instanceof WallCell)) {
        var cell = field.cells[y][x];
        if (getCellsOnLineOfFire) { // use getCellsOnLineOfFire only to generate line of fire, not to apply it
            if (!cell) {
                cell = new BaseCell(x, y);
            } // not return undefined elements, at least coords are necessary
            // (example: rugTile growing for petards in case of big that touch small
            //           but put grass in 1/2 of height, bottom cells destroyed
            //           but grass need to be grown because of small petard influence)
            iterator(cell);
        } else if (cell && cell.alive && cell.hurtable) {
            iterator(cell);
        }
        x += dir.x;
        y += dir.y;
    }
};

BaseCell.prototype.getGoalId = function () {
    var goalCoefComponent = this.findComponent(GoalCoefComponent);
    if (goalCoefComponent) {
        return goalCoefComponent.getGoalId();
    }

    return undefined;
};

BaseCell.prototype.boomNeighbors = function (coef) {
    var colorComponent = this.findComponent(ColorComponent);
    if (colorComponent) {
        colorComponent.boomNeighbors(coef);
    }
};

BaseCell.prototype.afterExplode = function (callback) {
    this.onAfterExplodeListener(this.x, this.y);

    this.onExplode();

    if (callback) {
        callback();
    }
};

BaseCell.prototype.explodeViaCollect = function (params) {
    params = params || {};
    var goalId;
    var amount = 1;
    var goalCoefComponent = this.findComponent(GoalCoefComponent);
    if (goalCoefComponent) {
        goalId = goalCoefComponent.getGoalId();
        amount += goalCoefComponent.coef;
    } else {
        goalId = this.getGoalId();
    }

    if (!Game.currentGame.goals.hasType(goalId)) {
        cleverapps.throwAsync("Collected cell not in goals!");
        this.explode(params);
        return;
    }

    if (!this.alive) {
        return;
    }
    this.alive = false;

    var goal = Game.currentGame.goals.findTargetFor(this.getGoalId());
    this.onBeforeExplodeListener(goal, amount);

    // different time, because we don't want to wait for collect animation to finish
    var EXPLODE_INTERVAL = this.explodeDuration();
    Game.currentGame.counter.setTimeout(this.afterExplode.bind(this, params.callback), EXPLODE_INTERVAL * 1000);
};

BaseCell.prototype.onDoubleTouch = function () {
    var component = this.findComponent([SpecialColorComponent, ColorComponent]);
    if (component) {
        component.onDoubleTouch();
    }
};

BaseCell.prototype.onTouchEnd = function () {
    if (!this.lastTouchEnd) {
        this.lastTouchEnd = 0;
    }
    var ct = Date.now();
    if (ct - this.lastTouchEnd < 500) {
        this.lastTouchEnd = 0;
        this.onDoubleTouch();
    } else {
        this.lastTouchEnd = ct;
    }
};

BaseCell.prototype.getRandomColor = function () {
    return cleverapps.Random.choose(Game.currentGame.levelContent.colors).toLowerCase();
};

BaseCell.prototype._explode = function (params) {
    if (!this.alive) {
        return;
    }

    this.alive = false;
    params = params || {};

    var EXPLODE_INTERVAL = this.explodeDuration();

    this.onBeforeExplodeListener(EXPLODE_INTERVAL);

    Game.currentGame.counter.setTimeout(this.afterExplode.bind(this, params.callback), EXPLODE_INTERVAL * 1000);
};

BaseCell.prototype.explode = function (params) {
    var markComponent = this.findComponent(MarkComponent);
    if (markComponent) {
        markComponent.explode();
    }

    var goalCoefOrColorComponent = this.findComponent([GoalCoefComponent, ColorComponent]);
    if (this.constructor === BaseCell && goalCoefOrColorComponent) {
        goalCoefOrColorComponent.explode(params);
        return;
    }

    var component = this.findComponent([SpecialShapeComponent, BigComponent, DecoratorComponent]);
    if (component && !component.explode(params)) {
        return;
    }

    this._explode(params);
};

BaseCell.prototype.simpleExplode = function (params) {
    var colorComponent = this.findComponent(ColorComponent);
    if (colorComponent) {
        if (colorComponent.simpleExplode(params)) {
            var goalCoefComponent = this.findComponent(GoalCoefComponent);
            if (goalCoefComponent) {
                BaseCell.prototype.explodeViaCollect.call(this, params);
            } else {
                this._explode(params);
            }
        }
        return;
    }

    throw "Not implemented";
};

BaseCell.prototype.prepareForBurn = function () {
    var goalCoefComponent = this.findComponent(GoalCoefComponent);
    if (goalCoefComponent) {
        goalCoefComponent.prepareForBurn();
    }

    this.willBurn = true;
};

BaseCell.prototype.beforeMoveByTransporter = function () {

};

BaseCell.prototype.getViewClass = function () {
    var component = this.findComponent([MuffinComponent, GoalCoefComponent, ColorComponent]);
    if (component) {
        return component.getViewClass();
    }

    throw "Not implemented";
};

BaseCell.prototype.useCustomView = function () {
    var colorComponent = this.findComponent(ColorComponent);
    if (colorComponent) {
        return colorComponent.useCustomView();
    }

    throw "Not implemented";
};

BaseCell.ANIMATION_DOWN = 0;
BaseCell.ANIMATION_UP = 1;
BaseCell.ANIMATION_RIGHT = 2;
BaseCell.ANIMATION_LEFT = 3;
BaseCell.ANIMATION_MISTAKE = 4;
BaseCell.ANIMATION_SHOWUP = 5;
BaseCell.ANIMATION_HIDE = 6;
BaseCell.ANIMATION_HURT = 7;
BaseCell.ANIMATION_MULTI_COLOR_SELECT = 8;
BaseCell.ANIMATION_CREATE = 9;
BaseCell.ANIMATION_SMILE = 10;
BaseCell.ANIMATION_CREATE_FROM_CELL = 11;
BaseCell.ANIMATION_OPEN = 12;
BaseCell.ANIMATION_ONCLICK = 13;
BaseCell.ANIMATION_COOKIE_UP = 14;
BaseCell.ANIMATION_SELECT = 15;
BaseCell.ANIMATION_CREATE_FROM_CENTER = 16;
BaseCell.ANIMATION_BOOM_NEIGHBOUR = 17;
BaseCell.ANIMATION_COOKIE_IN = 18;
BaseCell.ANIMATION_COOKIE_OUT = 19;
BaseCell.ANIMATION_TRANSPORTER_LOOP_START = 20;
BaseCell.ANIMATION_TRANSPORTER_LOOP_FINISH = 21;
BaseCell.ANIMATION_GOTO_POSITION = 22;
BaseCell.ANIMATION_ICE_MAKER_MOVE = 23;
BaseCell.ANIMATION_MOVE_TO = 24;
BaseCell.ANIMATION_CREATE_FROM_BOOSTER_BEFORE = 25;
BaseCell.ANIMATION_CREATE_FROM_LANTERN = 26;

CreateTypes = {};
CreateTypes.NOT_CREATE_AGAIN_TYPE = "x";
CreateTypes.CREATE_AGAIN_TYPE = "y";
CreateTypes.AUTO_CREATED_TYPE = "a";

BaseCell.DIRECTIONS = {};

BaseCell.UP = 0;
BaseCell.UP_RIGHT = 1;
BaseCell.RIGHT = 2;
BaseCell.DOWN_RIGHT = 3;
BaseCell.DOWN = 4;
BaseCell.DOWN_LEFT = 5;
BaseCell.LEFT = 6;
BaseCell.UP_LEFT = 7;

BaseCell.DIRECTIONS[BaseCell.UP] = { x: 0, y: -1 };
BaseCell.DIRECTIONS[BaseCell.UP_RIGHT] = { x: 1, y: -1 };
BaseCell.DIRECTIONS[BaseCell.RIGHT] = { x: 1, y: 0 };
BaseCell.DIRECTIONS[BaseCell.DOWN_RIGHT] = { x: 1, y: 1 };
BaseCell.DIRECTIONS[BaseCell.DOWN] = { x: 0, y: 1 };
BaseCell.DIRECTIONS[BaseCell.DOWN_LEFT] = { x: -1, y: 1 };
BaseCell.DIRECTIONS[BaseCell.LEFT] = { x: -1, y: 0 };
BaseCell.DIRECTIONS[BaseCell.UP_LEFT] = { x: -1, y: -1 };
