/**
 * Created by vladislav on 5/3/19
 */

MarkView.prototype.getImage = function () {
    if (!this.cell) {
        return bundles.game_mark.frames.mark_purple_png;
    }
    return ["b", "e", "w", "d"].indexOf(this.cell.getColor()) !== -1 ? bundles.game_mark.frames.mark_purple_png : bundles.game_mark.frames.mark_orange_png;
};

MarkView.prototype.getSkin = function () {
    if (!this.cell) {
        return "purple";
    }
    return ["b", "e", "w", "d"].indexOf(this.cell.getColor()) !== -1 ? "purple" : "orange";
};

MarkView.prototype.getGoalImage = function () {
    return this.getImage();
};