/**
 * Created by slava on 24.03.17.
 */

var HelpByFiveAttemptsWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.HelpByFiveAttemptsWindow;

        var animation = this.createAnimation();

        var text = this.createText();
        
        var content = new cleverapps.Layout([animation, text], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        this._super({
            title: "HelpByFiveAttempts.title",
            name: "helpbyfiveattemptswindow",
            content: content,
            button: {
                text: "OK",
                onPressed: this.close.bind(this)
            },
            openSound: bundles.help_by5_attempts_window.urls.perfect_effect
        });
    },

    createAnimation: function () {
        var styles = cleverapps.styles.HelpByFiveAttemptsWindow;

        var node = new cc.Node();
        node.setAnchorPoint(0.5, 0.5);
        node.setContentSize2(styles.animation);

        var animation = new cleverapps.Spine(bundles.help_by5_attempts_window.jsons.help_from_buddies_json);
        node.addChild(animation);
        animation.setPositionRound(styles.animation);
        animation.setAnimation(0, "animation", true);

        return node;
    },

    createText: function () {
        var styles = cleverapps.styles.HelpByFiveAttemptsWindow;

        var text = cleverapps.UI.generateOnlyText("HelpByFiveAttempts.text", cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setDimensions(styles.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        return text;
    },

    onClose: function () {
        if (Game.currentGame) {
            Game.currentGame.helpByFiveAttempts();
        }
    }
});

HelpByFiveAttemptsWindow.showIfCan = function () {
    if (!Game.currentGame.canHelpByFiveAttempts()) {
        return;
    }

    var amountAttempts = levels.levelAttempts.getAttempts();
    if (amountAttempts < 5 || amountAttempts % 5 !== 0) {
        return;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HELP_BY_FIVE_ATTEMPTS);

    cleverapps.focusManager.display({
        focus: "HelpByFiveAttemptsWindow",
        action: function (f) {
            new HelpByFiveAttemptsWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

HelpByFiveAttemptsWindow.prototype.listBundles = function () {
    return ["help_by5_attempts_window"];
};

cleverapps.styles.HelpByFiveAttemptsWindow = {
    margin: 20,

    animation: {
        width: 450,
        height: 400,

        x: { align: "center" },
        y: { align: "center", dy: -100 }
    },

    text: {
        width: 600
    }
};