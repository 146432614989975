/**
 * Created by decipaliz on 14.12.2023
 */

var Match3Goal = function (options, savedGoal) {
    Goal.call(this, options, savedGoal);
    savedGoal = savedGoal || {};
    this.amount = savedGoal.amount || 0;
    this.target = savedGoal.target || this.target;

    if (options.createOnCollect) {
        this.createOnCollect = options.createOnCollect;
        this.onCollectCreated = savedGoal.onCollectCreated || 0;
    }
};

Match3Goal.prototype = Object.create(Goal.prototype);
Match3Goal.prototype.constructor = Match3Goal;

Match3Goal.prototype.getInfo = function () {
    return {
        type: this.type,
        amount: (this.type === SyrupTile.GOAL_ID) ? undefined : this.amount,
        target: this.target,
        onCollectCreated: this.onCollectCreated
    };
};

Match3Goal.prototype._inc = Goal.prototype.inc;
Match3Goal.prototype.inc = function (amount, silent) {
    this._inc(amount, silent);

    Game.currentGame.score.updatePoints();

    if (this.createOnCollect) {
        if (this.onCollectCreated < this.createOnCollect.amount) {
            var leftAmount = this.createOnCollect.amount - this.onCollectCreated;
            var randomInterval = undefined;
            if (leftAmount > 5) {
                randomInterval = { start: 4, end: 7 };
            }
            if (leftAmount > 10) {
                randomInterval = { start: 2, end: 5 };
            }
            Game.currentGame.field.addNextCell(this.createOnCollect.type.split(""), randomInterval);
            this.onCollectCreated++;
        }
    }
};

Match3Goal.prototype.isBonusElement = function () {
    return this.type === CoinCell.GOAL_ID;
};

Match3Goal.prototype.animateBonus = function (f) {
    var actions = [];
    var duration = 800;

    var game = Game.currentGame;

    var addReward = game.addHardReward.bind(game, 1, true);
    var oneCoinDelay = 200;
    var needWaitFinish = false;

    this.trigger("beforeBonus");

    for (var i = 0; i < this.amount; i++) {
        actions.push(function (i, f) {
            if (game.skipBonusAnimations) {
                addReward();
                f();
                return;
            }

            needWaitFinish = true;

            if (oneCoinDelay > 5) {
                oneCoinDelay *= 0.9;
            }

            this.trigger("bonus", duration, addReward, i);

            cleverapps.timeouts.setTimeout(f, oneCoinDelay);
        }.bind(this, i));
    }

    actions.push(function (f) {
        cleverapps.timeouts.setTimeout(f, needWaitFinish ? duration : 0);
    });

    cleverapps.focusManager.compound(f, actions);
};

Goals.UP_DURATION = 0.2;
Goals.DEFAULT_NEXT_CELL_DURATION = 0.15;
Goals.MAX_NEXT_CELL_DURATION = 0.6;
Goals.WAIT_DURATION = 0.2;
