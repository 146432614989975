/**
 * Created by vlad on 17.10.18.
 */

FireworkCellView.prototype.outburst = function (cells) {
    this.collectToGoal();

    if (cells.length === 0) {
        return;
    }
    
    this.setLocalZOrder(1);

    this.runAction(new cc.Sequence(
        new cc.DelayTime(FireworkCell.HURT_DELAY),
        new cc.CallFunc(function () {
            this.animation.setAnimation(0, "animation", false);
            this.swapImageToAnimation();

            this.animation.setCompleteListener(function () {
                this.animation.visible = false;
                this.setLocalZOrder(this.originalZOrder);
            }.bind(this));
        }.bind(this))
    ));

    var startCell = this.cell;
    var skin = this.getSkin(this.cell);

    cells.forEach(function (cell, index) {
        var duration = this.cell.getFlyDuration(cell);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(FireworkCell.HURT_DELAY + index * FireworkCell.ANIMATION_DURATION),
            new cc.CallFunc(function () {
                var rocket = SimpleRocket(startCell, cell, {
                    delay: FireworkCell.ANIMATION_DURATION,
                    duration: duration,
                    skin: skin,
                    startDX: cleverapps.styles.FireworkCellView.bomb.startDX,
                    startDY: cleverapps.styles.FireworkCellView.bomb.startDY
                });

                this.parent.addChild(rocket);
                rocket.setLocalZOrder(11);
            }.bind(this))
        ));
    }.bind(this));
};

FireworkCellView.prototype.swapImageToAnimation = function () {
    this.runAction(new cc.CallFunc(function () {
        var colorComponent = this.cell.findComponent(ColorComponent);
        colorComponent.view.hide();

        this.animation.visible = true;
    }.bind(this)));
};

FireworkCellView.prototype.finishSmile = function () {
    if (this.smiling) {
        var colorComponent = this.cell.findComponent(ColorComponent);
        colorComponent.view.show();

        this.smiling = false;
        this.animation.visible = false;
        this.setLocalZOrder(2);
    }
};

FireworkCellView.prototype.smile = function () {
    if (!this.animation) {
        return;
    }

    if (this.smiling) {
        return;
    }
    this.smiling = true;
    this.animation.setAnimation(0, "smile", false);
    this.animation.setCompleteListener(this.finishSmile.bind(this));
    this.swapImageToAnimation();
};