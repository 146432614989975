/**
 * Created by slava on 31/7/19
 */

var SimpleRocket = function (start, goal, settings) {
    var rocketAnimation = new cleverapps.Spine(settings.json || bundles.game.jsons.firework_rocket_json);
    if (settings.skin) {
        rocketAnimation.setSkin(settings.skin);
    }
    rocketAnimation.setAnimation(0, "animation", true);

    var rocket = new cc.Node();
    rocket.setAnchorPoint(0.5, 0.5);
    rocket.setContentSize2(rocketAnimation.getContentSize());
    rocketAnimation.setPosition(rocket.width / 2, rocket.height / 2);
    rocket.addChild(rocketAnimation);

    settings.rocket = rocket;

    settings.onLaunch = function () {
        cleverapps.audio.playSound(bundles.game.urls.rocket_release);
    };

    var blastAnimation = new cleverapps.Spine(bundles.game.jsons.firework_blast_json);
    blastAnimation.setVisible(false);
    blastAnimation.setPosition(rocket.width / 2, rocket.height / 2);
    rocket.addChild(blastAnimation);

    settings.onArrive = function (removeRocketCallback) {
        cleverapps.audio.playSound(bundles.game.urls.rocket_bang);

        rocketAnimation.visible = false;

        blastAnimation.visible = true;
        blastAnimation.setAnimation(0, "animation", false);
        blastAnimation.setCompleteListener(function () {
            blastAnimation.visible = false;
            removeRocketCallback();
        });
    };

    return new Rocket(start, goal, settings);
};